import './signup.scss';
import React, {useEffect, useState, useContext} from 'react';

import {TextField, Button} from '@mui/material';

import GuestLayout from "../../components/layouts/guest";
import {context} from "../../context";
import AuthActions from '../../context/actions/auth';

export default () => {

    const {state, dispatch} = useContext(context);
    const [signupData, setSignupData] = useState({first_name: '', last_name: '', email: '', password: '', re_password: ''});

    const _submit = async (e) => {
        e.preventDefault();
        if(signupData.password !== signupData.re_password) {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: "Please enter the same Password Confirmation!" }
            });
        } else {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({
                type: AuthActions.REQUEST_SIGNUP,
                payload: {
                    first_name: signupData.first_name,
                    last_name: signupData.last_name,
                    email: signupData.email,
                    password: signupData.password
                }
            });
        }
    }

    const _changeSignupData = (e) => {
        switch (e.target.name) {
            case "first_name":
                setSignupData({
                    ...signupData,
                    first_name: e.target.value.toLowerCase()
                });
                break;
            case "last_name":
                setSignupData({
                    ...signupData,
                    last_name: e.target.value.toLowerCase()
                });
                break;
            case "email":
                setSignupData({
                    ...signupData,
                    email: e.target.value.toLowerCase()
                });
                break;
            case "password":
                setSignupData({
                    ...signupData,
                    password: e.target.value
                });
                break;
            case "re_password":
                setSignupData({
                    ...signupData,
                    re_password: e.target.value
                });
                if(e.target.value === signupData.password) {
                    e.target.setCustomValidity("");
                } else {
                    e.target.setCustomValidity("Please enter the same Password as above");
                }
                break;
            default:
                break;
        }
    }

    return (
        <GuestLayout>
            <div id="signup">
                <div className="wrapper">
                    <div className="left">
                        <h1 className="logo">
                            Welcome to <br/> Confidential Cloud Studio
                        </h1>
                        <span className="description">Easy, secure and compliant data collaboration </span>
                    </div>
                    <div className="right">
                        <form onSubmit={_submit} className="form" id="signup-form">
                            <div className="name">
                                <TextField
                                    id="input-first_name"
                                    className="input first_name"
                                    name="first_name"
                                    type="text"
                                    label="First Name"
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={_changeSignupData}
                                    value={signupData.first_name}
                                    required
                                />
                                <TextField
                                    id="input-last_name"
                                    className="input last_name"
                                    name="last_name"
                                    type="text"
                                    label="Last Name"
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={_changeSignupData}
                                    value={signupData.last_name}
                                    required
                                />
                            </div>

                            <TextField
                                id="input-email"
                                className="input email"
                                name="email"
                                type="email"
                                label="Your email"
                                variant="outlined"
                                autoComplete="off"
                                onChange={_changeSignupData}
                                value={signupData.email}
                                required
                            />

                            <TextField
                                id="input-password"
                                className="input password"
                                name="password"
                                type="password"
                                inputProps={{ pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{32,}" }}
                                onInput={(e) => {e.target.setCustomValidity("")}}
                                onInvalid={(e) => {e.target.setCustomValidity("The password must have at least 32 or more characters includes digits, lowercase, uppercase and special characters.")}}
                                label="Your password"
                                variant="outlined"
                                onChange={_changeSignupData}
                                value={signupData.password}
                                required
                            />

                            <TextField
                                id="input-re_password"
                                className="input re_password"
                                name="re_password"
                                type="password"
                                label="Confirm your password"
                                variant="outlined"
                                onChange={_changeSignupData}
                                value={signupData.re_password}
                                required
                            />

                            <Button type="submit" className="submit-btn" id="submit-btn">Sign Up</Button>

                            <ul className="helpers">
                                <li>
                                    <a href="/signin">Have already an accout?</a>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </GuestLayout>
    );
}
