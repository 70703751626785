import axios from "axios";
import _ from "lodash";

import Actions from '../actions/profile';

export default async (state, action, dispatch) => {
    switch(action.type) {
        case Actions.REQUEST_GET_AUTHENTICATED_USER:
            await _getCurrentUser(state, action, dispatch);
            break;

        case Actions.REQUEST_UPDATE_USER_PROFILE:
            await _updateProfile(state, action, dispatch);
            break;

        case Actions.REQUEST_UPDATE_COMPANY_INFO:
            await _updateCompanyInfo(state, action, dispatch);
            break;

        case Actions.REQUEST_CHANGE_PASSWORD:
            await _changePassword(state, action, dispatch);
            break;

        case Actions.REQUEST_NEW_CERTIFICATE:
            await _newCertificate(state, action, dispatch);
            break;
    }
}

const _getCurrentUser = async (state, action, dispatch) => {
    let accessToken = window.localStorage.getItem('access_token');
    if (_.isEmpty(accessToken)) {
        dispatch({type: Actions.RESPONSE_GET_AUTHENTICATED_USER_FAILED, payload: {user: {}}});
        return false;
    }

    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/profile`,
            headers: {'Authorization': `Bearer ${accessToken}`}
        });
        const user = httpResponse.data.data;
        if (_.isEmpty(user)) {
            window.localStorage.removeItem('access_token');
            dispatch({type: Actions.RESPONSE_GET_AUTHENTICATED_USER_FAILED, payload: {user: {}}});
            return false;
        }

        user.access_token = accessToken;
        dispatch({type: Actions.RESPONSE_GET_AUTHENTICATED_USER_SUCCESS, payload: {user}});
        return true;
    } catch (e) {
        console.log(e.message);
        dispatch({type: Actions.RESPONSE_GET_AUTHENTICATED_USER_FAILED, payload: {user: {}}});
        return false;
    }
}

const _updateProfile = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/profile`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
            data: action.payload
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_UPDATE_USER_PROFILE_SUCCESS, payload: appResponse.data });
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'Update profile successful!',
                    }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
            setTimeout(function () {
                window.location.reload();
            }, 1500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _updateCompanyInfo = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/company`,
            headers: {
                'Authorization': `Bearer ${state.authenticated_user.access_token}`,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: action.payload
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_UPDATE_COMPANY_INFO_SUCCESS, payload: appResponse.data });
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'Update profile successful!',
                    }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
            setTimeout(function () {
                window.location.reload();
            }, 1500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _newCertificate = async (state, action, dispatch) => {
    try {
        let file = action.target.files[0];

        let formData = new FormData();
        formData.append(
            "x509_certificate_file",
            file,
            file.name
        );
        const httpResponse = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/x509-certificate`,
            headers: {
                'Authorization': `Bearer ${state.authenticated_user.access_token}`,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: formData
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_NEW_CERTIFICATE_SUCCESS, payload: appResponse.data });
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'you just uploaded a new certificate!',
                    }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }

    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _changePassword = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/profile/change-password`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
            data: action.payload
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_CHANGE_PASSWORD_SUCCESS, payload: appResponse.data });
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'Change password successful!',
                    }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};
