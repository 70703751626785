import Actions from '../actions/profile';

export default (state, action) => {
    switch(action.type) {
        case Actions.RESPONSE_GET_AUTHENTICATED_USER_FAILED:
        case Actions.RESPONSE_GET_AUTHENTICATED_USER_SUCCESS:
            return action.payload.user;

        default:
            return state;
    }
};
