export default (state, action) => {
    switch(action.type) {
        case 'SHOW_GLOBAL_MESSAGE':
            return {
                open: action.payload.open,
                type: action.payload.type,
                message: action.payload.message
            }

        case 'HIDE_GLOBAL_MESSAGE':
            return {
                ...state,
                open: false
            }

        default:
            return state;
    }
};
