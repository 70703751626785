import './execution.scss';
import React, {useEffect, useState, useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import _ from "lodash";

import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AppLayout from "../../../components/layouts/app";
import CollaborationActions from '../../../context/actions/collaborations'
import PartnerActions from '../../../context/actions/partners'
import LogActions from '../../../context/actions/logs'
import {context} from "../../../context";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

export default () => {
    const {state, dispatch} = useContext(context);
    const {log_id} = useParams();
    const {log_asset_id} = useParams();
    const this_page_collab_id = log_id
    const this_page_asset_id = log_asset_id

    useEffect(async () => {
        if(state.logs.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: LogActions.REQUEST_GET_LOGS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.logs.index]);

    useEffect(async () => {
        if(state.collaborations.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: CollaborationActions.REQUEST_GET_COLLABORATIONS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.collaborations.index]);

    useEffect(async () => {
        if(state.partners.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNERS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.partners.index]);

    let this_collab = null
    if(state.logs.index !== null){
        this_collab = state.logs.index.items.filter(function(item){
            return item._id === this_page_collab_id;
        })[0].collaboration_id
    }

    let this_page_asset = null
    if(state.logs.index !== null){
        this_page_asset = state.logs.index.items.filter(function(item){
            return item.asset_id === this_page_asset_id;
        })[0].data
    }

    useEffect(async () => {
        if(state.partners.detail.applications === null && this_collab !== null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNER_APPLICATIONS, payload: {collaborationId: this_collab} });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.logs.index]);

    useEffect(async () => {
        if(state.partners.detail.datasets === null && this_collab !== null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNER_DATASETS, payload: {collaborationId: this_collab} });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.logs.index]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let logItems = _.get(state, 'logs.index.items', []);

    if(logItems.length !== 0){     //filtering to get only the execution
        logItems = logItems.filter(function(item){
            return (item.type === "execution" && item.data.main_asset_id === this_page_asset_id)
        })
    }

    let totalRecord = logItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);

    const _renderLogsRequests = () => {

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;

        const listLogs = logItems.filter(function (log, index) {
            return index >= from && index <= to;
        }).map(function (log) {

            let Started = (log.data !== undefined) ?
                log.data.start_date : "....."

            let Ended = (log.data !== undefined) ?
                log.data.end_date : "....."

            let Cloud = (log.data !== undefined) ?
                log.data.cloud_provider : "....."

            let Other_Asset = (log.data !== undefined) ?
                log.data.other_asset_name : "....."

            let Results_endpoint = (log.data !== undefined) ?
                log.data.endpoints  : "....."

            let link_endpoints = "/results"
            let link_asset = "/"
            if (log.data !== undefined){
                if (log.data.asset_type === "application"){
                    link_asset = "/applications/" + log.data.other_asset_id;
                }
                if (log.data.asset_type === "dataset"){
                    link_asset = "/datasets/" + log.data.other_asset_id;
                }
            }

            let listAttestation = JSON.parse(log.data?.list_attestation || "[]");
            let attestation = _.find(listAttestation, { 'user_id': state.authenticated_user._id });

            return (
                <tr key={log._id}>
                    <td>{Started}</td>
                    <td>{Ended}</td>
                    <td>{Cloud}</td>
                    <td><a href={link_asset}>{Other_Asset}</a></td>
                    <td>
                        <Link to={_.isEmpty(attestation) ? "/attestations" : `/attestations/${attestation.attestation_id}`} >
                                <VerifiedUserIcon className="icon"/>
                        </Link>
                    </td>
                    <td><a href={link_endpoints}>{Results_endpoint}</a></td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Started</th>
                    <th>Ended</th>
                    <th>Cloud Provider</th>
                    <th>Secondary Asset</th>
                    <th>Attestation</th>
                    <th>My Endpoint</th>
                </tr>
                </thead>
                <tbody>
                { listLogs }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.logs.index.items.length < state.logs.total)) {
                dispatch({ type: LogActions.REQUEST_GET_LOGS });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }
    const _log = () => {
        if(this_collab !== null){
            let partnerName = (state.collaborations.index !== null) ?
                state.collaborations.index.items.filter(function(item) {
                    return item._id === this_collab;
                })[0].company.name :
                "......";

            return (
                <li>
                    <Link to={`/logs/${log_id}`}>
                        <span>{partnerName}</span>
                    </Link>
                </li>
            );
        }
    };

    return (
        <AppLayout>
            <div id="partners-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/logs" >
                                <PsychologyOutlinedIcon className="icon"/>
                                <span>Audit Logs</span>
                            </Link>
                        </li>
                        <li className="divider">/</li>
                        <li>{_log()}</li>
                        <li className="divider">/</li>
                        <li>{this_page_asset?.cached_name}</li>
                    </ul>


                </div>

                <div className="card">
                    { _renderLogsRequests() }
                    { _renderPagination() }
                </div>

            </div>
        </AppLayout>
    );
};
