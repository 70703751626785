import axios from "axios";
import _ from "lodash";

import Actions from '../actions/notifications';

export default async (state, action, dispatch) => {
    switch(action.type) {
        case Actions.REQUEST_GET_NOTIFICATIONS:
            await _getNotifications(state, action, dispatch);
            break;
    }
}

const _getNotifications = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/notifications`,
            params: {
                page: _.get(state, 'notifications.current_page', 0) + 1
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        dispatch({ type: Actions.RESPONSE_GET_NOTIFICATIONS_SUCCESS, payload: appResponse.data });
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }

}
