export default {
    REQUEST_GET_APPLICATIONS: "REQUEST_GET_APPLICATIONS",
    RESPONSE_GET_APPLICATIONS_SUCCESS: "RESPONSE_GET_APPLICATIONS_SUCCESS",
    RESPONSE_GET_APPLICATIONS_FAILED: "RESPONSE_GET_APPLICATIONS_FAILED",

    REQUEST_GET_APPLICATION_DETAIL: "REQUEST_GET_APPLICATION_DETAIL",
    RESPONSE_GET_APPLICATION_DETAIL_SUCCESS: "RESPONSE_GET_APPLICATION_DETAIL_SUCCESS",
    RESPONSE_GET_APPLICATION_DETAIL_FAILED: "RESPONSE_GET_APPLICATION_DETAIL_FAILED",

    REQUEST_CREATE_NEW_APPLICATION: "REQUEST_CREATE_NEW_APPLICATION",
    RESPONSE_CREATE_NEW_APPLICATION_SUCCESS: "RESPONSE_CREATE_NEW_APPLICATION_SUCCESS",
    RESPONSE_CREATE_NEW_APPLICATION_FAILED: "RESPONSE_CREATE_NEW_APPLICATION_FAILED",

    REQUEST_DELETE_APPLICATION: "REQUEST_DELETE_APPLICATION",
    RESPONSE_DELETE_APPLICATION_SUCCESS: "RESPONSE_DELETE_APPLICATION_SUCCESS",
    RESPONSE_DELETE_APPLICATION_FAILED: "RESPONSE_DELETE_APPLICATION_FAILED",
}
