export default {
    REQUEST_GET_AUTHENTICATED_USER: "REQUEST_GET_AUTHENTICATED_USER",
    RESPONSE_GET_AUTHENTICATED_USER_SUCCESS: "RESPONSE_GET_AUTHENTICATED_USER_SUCCESS",
    RESPONSE_GET_AUTHENTICATED_USER_FAILED: "RESPONSE_GET_AUTHENTICATED_USER_FAILED",

    REQUEST_UPDATE_COMPANY_INFO: "REQUEST_UPDATE_COMPANY_INFO",
    RESPONSE_UPDATE_COMPANY_INFO_SUCCESS: "RESPONSE_UPDATE_COMPANY_INFO_SUCCESS",
    RESPONSE_UPDATE_COMPANY_INFO_FAILED: "RESPONSE_UPDATE_COMPANY_INFO_FAILED",

    REQUEST_UPDATE_USER_PROFILE: "REQUEST_UPDATE_USER_PROFILE",
    RESPONSE_UPDATE_USER_PROFILE_SUCCESS: "RESPONSE_UPDATE_USER_PROFILE_SUCCESS",
    RESPONSE_UPDATE_USER_PROFILE_FAILED: "RESPONSE_UPDATE_USER_PROFILE_FAILED",

    REQUEST_NEW_CERTIFICATE: "REQUEST_NEW_CERTIFICATE",
    RESPONSE_NEW_CERTIFICATE_SUCCESS: "RESPONSE_NEW_CERTIFICATE_SUCCESS",
    RESPONSE_NEW_CERTIFICATE_FAILED: "RESPONSE_NEW_CERTIFICATE_FAILED",

    REQUEST_CHANGE_PASSWORD: "REQUEST_CHANGE_PASSWORD",
    RESPONSE_CHANGE_PASSWORD_SUCCESS: "RESPONSE_CHANGE_PASSWORD_SUCCESS",
    RESPONSE_CHANGE_PASSWORD_FAILED: "RESPONSE_CHANGE_PASSWORD_FAILED",
}
