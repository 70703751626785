import './index.scss';
import {Link} from "react-router-dom";
import React, {useEffect, useState, useContext} from 'react';
import _ from "lodash";

import AppLayout from "../../components/layouts/app";
import {context} from "../../context";
import AttestationActions from '../../context/actions/attestations';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

export default () => {
    const {state, dispatch} = useContext(context);

    useEffect(async () => {
        if(state.attestations.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: AttestationActions.REQUEST_GET_ATTESTATIONS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.attestations.index]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let attestationItems = _.get(state, 'attestations.index.items', []);
    let totalRecord = attestationItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);
    const _renderAttestations = () => {
        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listAttestations = attestationItems.filter(function (attestation, index) {
            return index >= from && index <= to;
        }).map(function (attestation) {
            let collaborators = attestation?.collaborators ? JSON.parse(attestation.collaborators) : [];
            let collaboratorNames = collaborators.map(collaborator => (<li>{collaborator.company.name}</li>));

            return (
                <tr key={attestation._id}>
                    <td>{attestation.enclave.project_id}</td>
                    <td>{attestation.created_at}</td>
                    <td><ul>{collaboratorNames}</ul></td>
                    <td>
                        <Link to={`/attestations/${attestation._id}`}>
                            <VisibilityIcon sx={{ color: 'rgb(25, 118, 210)' }} />
                        </Link>
                    </td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Enclave ID</th>
                    <th>Created At</th>
                    <th>Collaborators</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { listAttestations }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.attestations.index.items.length < state.attestations.index.total)) {
                dispatch({ type: AttestationActions.REQUEST_GET_ATTESTATIONS });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    return (
        <AppLayout>
            <div id="attestations-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/enclaves" className="disabled" >
                                <VerifiedUserIcon className="icon"/>
                                <span>Attestations</span>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="card">
                    { _renderAttestations() }

                    { _renderPagination() }
                </div>
            </div>
        </AppLayout>
    );
};
