import './under_review.scss';
import React, {useEffect, useState, useContext} from 'react';

import GuestLayout from "../../../components/layouts/guest";
import {context} from "../../../context";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

export default () => {

    const {state, dispatch} = useContext(context);

    const steps = [
        'Upload X.509 Certificate',
        'Update Company Profile',
        'Review',
    ];

    const _logout = (e) => {
        e.preventDefault();
        dispatch({ type: "SHOW_GLOBAL_LOADING" });

        setTimeout(function () {
            window.localStorage.removeItem('access_token');
            window.location.replace('/signin');
        }, 1000);
    }

    return (
        <GuestLayout>
            <div id="under-review">
                <div className="wrapper">
                    <div className="brand">
                        <img src="/confidential_cloud_studio.png" alt=""/>
                    </div>

                    <div className="stepper">
                        <Stepper activeStep={2} alternativeLabel={true}>
                            {steps.map((label) => (
                                <Step>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>

                    <div className="box">
                        <h2 className="title">Your account is under review!</h2>
                        <p className="description">
                            Thank you for joining Confidential Cloud Studio. <br/>
                            We will review your details shortly and grant you access <strong>within 48h</strong>.
                        </p>
                    </div>
                    <div className="footer">
                        <p className="logout"><span onClick={_logout}>Logout!</span></p>
                    </div>
                </div>
            </div>
        </GuestLayout>
    );
}
