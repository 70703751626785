import axios from "axios";
import _ from "lodash";

import enclaveActions from '../actions/enclaves';
import attestationActions from '../actions/attestations';
import resultActions from '../actions/results';

export default async (state, action, dispatch) => {
    switch(action.type) {
        case enclaveActions.REQUEST_GET_ENCLAVES:
            await _getEnclaves(state, action, dispatch);
            break;

        case enclaveActions.REQUEST_CREATE_NEW_ENCLAVE:
            await _createEnclave(state, action, dispatch);
            break;
    }
}

const _getEnclaves = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/enclaves`,
            params: {
                page: _.get(state, 'enclaves.current_page', 0) + 1
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        dispatch({ type: enclaveActions.RESPONSE_GET_ENCLAVES_SUCCESS, payload: appResponse.data });
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }

}

const _createEnclave = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/enclaves`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
            data: action.payload
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: enclaveActions.RESPONSE_CREATE_NEW_ENCLAVE_SUCCESS, payload: appResponse.data });
            dispatch({ type: attestationActions.RESPONSE_GET_ATTESTATIONS_SUCCESS, payload: null });
            dispatch({ type: resultActions.RESPONSE_GET_RESULTS_SUCCESS, payload: null });
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'New enclave was added!',
                    }
                });
            }, 500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }

}
