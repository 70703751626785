import Auth from './auth';
import Profile from './profile';
import Applications from './applications';
import Datasets from './datasets';
import Enclaves from './enclaves';
import Attestations from './attestations';
import Collaborations from './collaborations';
import Partners from './partners';
import Results from './results';
import Notifications from './notifications';
import Logs from './logs';
import Billing from './billing';
import Companies from './companies';

export default [
    Auth,
    Profile,
    Applications,
    Datasets,
    Enclaves,
    Attestations,
    Collaborations,
    Partners,
    Results,
    Notifications,
    Logs,
    Billing,
    Companies
];
