import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import {Link} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import _ from "lodash";

import AppLayout from "../../components/layouts/app";
import {context} from "../../context";
import PartnerActions from '../../context/actions/partners';
import BusinessIcon from '@mui/icons-material/Business';
import Button from "@mui/material/Button";
import CollaborationActions from "../../context/actions/collaborations";
import CompanyActions from "../../context/actions/companies";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default () => {
    const {state, dispatch} = useContext(context);

    useEffect(async () => {
        if(state.partners.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNERS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.RESPONSE_GET_PARTNER_DETAIL_FAILED }); // remove detail data
        }
    }, [state.partners.index]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let partnerItems = _.get(state, 'partners.index.items', []);
    let totalRecord = partnerItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);
    const _renderPartners = () => {

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listPartnerItems = partnerItems.filter(function (partner, index) {
            return index >= from && index <= to;
        }).map(function (partner) {
            return (
                <tr key={partner._id}>
                    <td>{partner.company.name}</td>
                    <td>{`${partner.main_contact.first_name} ${partner.main_contact.last_name}`}</td>
                    <td>{partner.main_contact.email}</td>
                    <td><Link to={`/partners/${partner._id}`} className="link"><VisibilityIcon sx={{ color: 'rgb(25, 118, 210)' }} /></Link></td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Company Name</th>
                    <th>Main Contact</th>
                    <th>Email</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { listPartnerItems }
                </tbody>
            </table>
        );
    }

    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.partners.index.items.length < state.partners.index.total)) {
                dispatch({ type: PartnerActions.REQUEST_GET_PARTNERS });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    const [openCreatingModal, setOpenCreatingModal] = useState(false);
    const [searchingKeyword, setSearchingKeyword] = useState("");
    const _renderCreatingModal = () => {
        if(!openCreatingModal) { return (<div />); }

        const _submit = async (e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(_.isEmpty(state.authenticated_user.company)) {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: "You have to complete your company profile before you can send the collaboration request" }
                });
            } else {
                if(searchingKeyword.length < 4) {
                    dispatch({
                        type: "SHOW_GLOBAL_MESSAGE",
                        payload: { open: true, type: 'error', message: "Your keyword should be at least 4 characters!" }
                    });
                } else {
                    dispatch({ type: CompanyActions.REQUEST_SEARCH_COMPANIES, payload: {keyword: searchingKeyword} });
                }
            }

            setTimeout(function () {
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }

        const _requestCreateNewCollaboration = (collaboratorEmail) => {
            console.log("_requestCreateNewCollaboration: ", collaboratorEmail)
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            dispatch({ type: CollaborationActions.REQUEST_CREATE_NEW_COLLABORATION, payload: {email: collaboratorEmail} });

            setTimeout(function () {
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }

        const _renderSearchingResult = () => {
            let results = state.companies.search.results;
            if(_.isEmpty(results)) {
                return (<div/>);
            }

            let records = results.map(function (record, index) {
                if(index >= 6) return false; // TODO: pagination

                let accepted = _.find(state.partners.index.items, function(o) { return o.main_contact.email === record.main_contact.email; });

                return (
                    <tr>
                        <td>{record.company.name}</td>
                        <td>{record.main_contact.first_name + " " + record.main_contact.last_name}</td>
                        <td>{record.main_contact.email}</td>
                        <td>
                            {
                                accepted ?
                                    <span className='accepted'>Accepted</span> :
                                    <span className="actions">
                                        <Button variant="outlined" onClick={() => _requestCreateNewCollaboration(record.main_contact.email)}>Request</Button>
                                    </span>
                            }
                        </td>
                    </tr>
                );
            });

            return (
                <table className="results">
                    <thead>
                    <tr>
                        <th>Company Name</th>
                        <th>Main Contact</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {records}
                    </tbody>
                </table>
            );
        }

        return (
            <Modal
                id="modal-collaborations-create-new"
                open={openCreatingModal}
                onClose={() => setOpenCreatingModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openCreatingModal}>
                    <Box className="modal-content">
                        <form onSubmit={_submit}>
                            <div className="header">
                                <h2 className="title">Add New Collaborator</h2>
                            </div>
                            <div className="form">
                                <TextField
                                    required
                                    label="Keyword"
                                    type="text"
                                    name="keyword"
                                    fullWidth={true}
                                    value={searchingKeyword}
                                    onChange={(e) => {setSearchingKeyword(e.target.value)}}
                                />
                            </div>

                            {_renderSearchingResult()}

                            <div className="footer">
                                <Button variant="outlined" onClick={() => setOpenCreatingModal(false)}>Cancel</Button>
                                <Button variant="contained" type="submit" id="send-request-button">Search</Button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        );
    }

    return (
        <AppLayout>
            <div id="partners-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/partners" className="disabled" >
                                <BusinessIcon className="icon"/>
                                <span>Partners</span>
                            </Link>
                        </li>
                    </ul>

                    <Button variant="outlined" id="requests-button">
                        <Link to="/collaborations">Requests</Link>
                    </Button>

                    <Button variant="outlined" id="add-partner-button" onClick={() => setOpenCreatingModal(true)}>
                        Add Partner
                    </Button>
                </div>

                <div className="card">
                    { _renderPartners() }
                    { _renderPagination() }
                </div>

                { _renderCreatingModal() }
            </div>
        </AppLayout>
    );
};
