export default {
    REQUEST_GET_LOGS: "REQUEST_GET_LOGS",
    RESPONSE_GET_LOGS_SUCCESS: "RESPONSE_GET_LOGS_SUCCESS",
    RESPONSE_GET_LOGS_FAILED: "RESPONSE_GET_LOGS_FAILED",

    REQUEST_GET_LOG_DETAIL: "REQUEST_GET_LOG_DETAIL",
    RESPONSE_GET_LOG_DETAIL_SUCCESS: "RESPONSE_GET_LOG_DETAIL_SUCCESS",
    RESPONSE_GET_LOG_DETAIL_FAILED: "RESPONSE_GET_LOG_DETAIL_FAILED",

    REQUEST_CREATE_NEW_LOG: "REQUEST_CREATE_NEW_LOG",
    RESPONSE_CREATE_NEW_LOG_SUCCESS: "RESPONSE_CREATE_NEW_LOG_SUCCESS",
    RESPONSE_CREATE_NEW_LOG_FAILED: "RESPONSE_CREATE_NEW_LOG_FAILED",

    REQUEST_DELETE_LOGS: "REQUEST_DELETE_LOGS",
    RESPONSE_DELETE_LOG_SUCCESS: "RESPONSE_DELETE_LOG_SUCCESS",
    RESPONSE_DELETE_LOG_FAILED: "RESPONSE_DELETE_LOG_FAILED",
}
