import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
//import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
//import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const Featured = () => {
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">EXECUTIONS</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={90} text={"17"} strokeWidth={5} />
        </div>
        <p className="title">Today's Executions</p>
        <p className="amount">7</p>
        <p className="desc">
          Previous execution processing. Last execution may not be included.
        </p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Average / month</div>
            <div className="itemResult">
              <p className="amount">17</p>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult">
                <p className="amount">4</p>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult">
              <p className="amount">19</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
