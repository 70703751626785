import 'react-image-crop/src/ReactCrop.scss'
import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import _ from "lodash";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MuiPhoneNumber from 'material-ui-phone-number';
import Button from '@mui/material/Button';
import {TextField} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from '@mui/icons-material/Apartment';
import ReactCrop from 'react-image-crop'
import Autocomplete from '@mui/material/Autocomplete';

import AppLayout from "../../components/layouts/app";
import {context} from "../../context";
import ProfileActions from '../../context/actions/profile';
import {Link} from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import axios from "axios";

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
    {
        "code": "ad",
        "name": "andorra",
        "phone": "376"
    },
    {
        "code": "ae",
        "name": "united arab emirates",
        "phone": "971"
    },
    {
        "code": "af",
        "name": "afghanistan",
        "phone": "93"
    },
    {
        "code": "ag",
        "name": "antigua and barbuda",
        "phone": "1-268"
    },
    {
        "code": "ai",
        "name": "anguilla",
        "phone": "1-264"
    },
    {
        "code": "al",
        "name": "albania",
        "phone": "355"
    },
    {
        "code": "am",
        "name": "armenia",
        "phone": "374"
    },
    {
        "code": "ao",
        "name": "angola",
        "phone": "244"
    },
    {
        "code": "aq",
        "name": "antarctica",
        "phone": "672"
    },
    {
        "code": "ar",
        "name": "argentina",
        "phone": "54"
    },
    {
        "code": "as",
        "name": "american samoa",
        "phone": "1-684"
    },
    {
        "code": "at",
        "name": "austria",
        "phone": "43"
    },
    {
        "code": "au",
        "name": "australia",
        "phone": "61"
    },
    {
        "code": "aw",
        "name": "aruba",
        "phone": "297"
    },
    {
        "code": "ax",
        "name": "alland islands",
        "phone": "358"
    },
    {
        "code": "az",
        "name": "azerbaijan",
        "phone": "994"
    },
    {
        "code": "ba",
        "name": "bosnia and herzegovina",
        "phone": "387"
    },
    {
        "code": "bb",
        "name": "barbados",
        "phone": "1-246"
    },
    {
        "code": "bd",
        "name": "bangladesh",
        "phone": "880"
    },
    {
        "code": "be",
        "name": "belgium",
        "phone": "32"
    },
    {
        "code": "bf",
        "name": "burkina faso",
        "phone": "226"
    },
    {
        "code": "bg",
        "name": "bulgaria",
        "phone": "359"
    },
    {
        "code": "bh",
        "name": "bahrain",
        "phone": "973"
    },
    {
        "code": "bi",
        "name": "burundi",
        "phone": "257"
    },
    {
        "code": "bj",
        "name": "benin",
        "phone": "229"
    },
    {
        "code": "bl",
        "name": "saint barthelemy",
        "phone": "590"
    },
    {
        "code": "bm",
        "name": "bermuda",
        "phone": "1-441"
    },
    {
        "code": "bn",
        "name": "brunei darussalam",
        "phone": "673"
    },
    {
        "code": "bo",
        "name": "bolivia",
        "phone": "591"
    },
    {
        "code": "br",
        "name": "brazil",
        "phone": "55"
    },
    {
        "code": "bs",
        "name": "bahamas",
        "phone": "1-242"
    },
    {
        "code": "bt",
        "name": "bhutan",
        "phone": "975"
    },
    {
        "code": "bv",
        "name": "bouvet island",
        "phone": "47"
    },
    {
        "code": "bw",
        "name": "botswana",
        "phone": "267"
    },
    {
        "code": "by",
        "name": "belarus",
        "phone": "375"
    },
    {
        "code": "bz",
        "name": "belize",
        "phone": "501"
    },
    {
        "code": "ca",
        "name": "canada",
        "phone": "1"
    },
    {
        "code": "cc",
        "name": "cocos (keeling) islands",
        "phone": "61"
    },
    {
        "code": "cd",
        "name": "congo, democratic republic of the",
        "phone": "243"
    },
    {
        "code": "cf",
        "name": "central african republic",
        "phone": "236"
    },
    {
        "code": "cg",
        "name": "congo, republic of the",
        "phone": "242"
    },
    {
        "code": "ch",
        "name": "switzerland",
        "phone": "41"
    },
    {
        "code": "ci",
        "name": "cote d'ivoire",
        "phone": "225"
    },
    {
        "code": "ck",
        "name": "cook islands",
        "phone": "682"
    },
    {
        "code": "cl",
        "name": "chile",
        "phone": "56"
    },
    {
        "code": "cm",
        "name": "cameroon",
        "phone": "237"
    },
    {
        "code": "cn",
        "name": "china",
        "phone": "86"
    },
    {
        "code": "co",
        "name": "colombia",
        "phone": "57"
    },
    {
        "code": "cr",
        "name": "costa rica",
        "phone": "506"
    },
    {
        "code": "cu",
        "name": "cuba",
        "phone": "53"
    },
    {
        "code": "cv",
        "name": "cape verde",
        "phone": "238"
    },
    {
        "code": "cw",
        "name": "curacao",
        "phone": "599"
    },
    {
        "code": "cx",
        "name": "christmas island",
        "phone": "61"
    },
    {
        "code": "cy",
        "name": "cyprus",
        "phone": "357"
    },
    {
        "code": "cz",
        "name": "czech republic",
        "phone": "420"
    },
    {
        "code": "de",
        "name": "germany",
        "phone": "49"
    },
    {
        "code": "dj",
        "name": "djibouti",
        "phone": "253"
    },
    {
        "code": "dk",
        "name": "denmark",
        "phone": "45"
    },
    {
        "code": "dm",
        "name": "dominica",
        "phone": "1-767"
    },
    {
        "code": "do",
        "name": "dominican republic",
        "phone": "1-809"
    },
    {
        "code": "dz",
        "name": "algeria",
        "phone": "213"
    },
    {
        "code": "ec",
        "name": "ecuador",
        "phone": "593"
    },
    {
        "code": "ee",
        "name": "estonia",
        "phone": "372"
    },
    {
        "code": "eg",
        "name": "egypt",
        "phone": "20"
    },
    {
        "code": "eh",
        "name": "western sahara",
        "phone": "212"
    },
    {
        "code": "er",
        "name": "eritrea",
        "phone": "291"
    },
    {
        "code": "es",
        "name": "spain",
        "phone": "34"
    },
    {
        "code": "et",
        "name": "ethiopia",
        "phone": "251"
    },
    {
        "code": "fi",
        "name": "finland",
        "phone": "358"
    },
    {
        "code": "fj",
        "name": "fiji",
        "phone": "679"
    },
    {
        "code": "fk",
        "name": "falkland islands (malvinas)",
        "phone": "500"
    },
    {
        "code": "fm",
        "name": "micronesia, federated states of",
        "phone": "691"
    },
    {
        "code": "fo",
        "name": "faroe islands",
        "phone": "298"
    },
    {
        "code": "fr",
        "name": "france",
        "phone": "33"
    },
    {
        "code": "ga",
        "name": "gabon",
        "phone": "241"
    },
    {
        "code": "gb",
        "name": "united kingdom",
        "phone": "44"
    },
    {
        "code": "gd",
        "name": "grenada",
        "phone": "1-473"
    },
    {
        "code": "ge",
        "name": "georgia",
        "phone": "995"
    },
    {
        "code": "gf",
        "name": "french guiana",
        "phone": "594"
    },
    {
        "code": "gg",
        "name": "guernsey",
        "phone": "44"
    },
    {
        "code": "gh",
        "name": "ghana",
        "phone": "233"
    },
    {
        "code": "gi",
        "name": "gibraltar",
        "phone": "350"
    },
    {
        "code": "gl",
        "name": "greenland",
        "phone": "299"
    },
    {
        "code": "gm",
        "name": "gambia",
        "phone": "220"
    },
    {
        "code": "gn",
        "name": "guinea",
        "phone": "224"
    },
    {
        "code": "gp",
        "name": "guadeloupe",
        "phone": "590"
    },
    {
        "code": "gq",
        "name": "equatorial guinea",
        "phone": "240"
    },
    {
        "code": "gr",
        "name": "greece",
        "phone": "30"
    },
    {
        "code": "gs",
        "name": "south georgia and the south sandwich islands",
        "phone": "500"
    },
    {
        "code": "gt",
        "name": "guatemala",
        "phone": "502"
    },
    {
        "code": "gu",
        "name": "guam",
        "phone": "1-671"
    },
    {
        "code": "gw",
        "name": "guinea-bissau",
        "phone": "245"
    },
    {
        "code": "gy",
        "name": "guyana",
        "phone": "592"
    },
    {
        "code": "hk",
        "name": "hong kong",
        "phone": "852"
    },
    {
        "code": "hm",
        "name": "heard island and mcdonald islands",
        "phone": "672"
    },
    {
        "code": "hn",
        "name": "honduras",
        "phone": "504"
    },
    {
        "code": "hr",
        "name": "croatia",
        "phone": "385"
    },
    {
        "code": "ht",
        "name": "haiti",
        "phone": "509"
    },
    {
        "code": "hu",
        "name": "hungary",
        "phone": "36"
    },
    {
        "code": "id",
        "name": "indonesia",
        "phone": "62"
    },
    {
        "code": "ie",
        "name": "ireland",
        "phone": "353"
    },
    {
        "code": "il",
        "name": "israel",
        "phone": "972"
    },
    {
        "code": "im",
        "name": "isle of man",
        "phone": "44"
    },
    {
        "code": "in",
        "name": "india",
        "phone": "91"
    },
    {
        "code": "io",
        "name": "british indian ocean territory",
        "phone": "246"
    },
    {
        "code": "iq",
        "name": "iraq",
        "phone": "964"
    },
    {
        "code": "ir",
        "name": "iran, islamic republic of",
        "phone": "98"
    },
    {
        "code": "is",
        "name": "iceland",
        "phone": "354"
    },
    {
        "code": "it",
        "name": "italy",
        "phone": "39"
    },
    {
        "code": "je",
        "name": "jersey",
        "phone": "44"
    },
    {
        "code": "jm",
        "name": "jamaica",
        "phone": "1-876"
    },
    {
        "code": "jo",
        "name": "jordan",
        "phone": "962"
    },
    {
        "code": "jp",
        "name": "japan",
        "phone": "81"
    },
    {
        "code": "ke",
        "name": "kenya",
        "phone": "254"
    },
    {
        "code": "kg",
        "name": "kyrgyzstan",
        "phone": "996"
    },
    {
        "code": "kh",
        "name": "cambodia",
        "phone": "855"
    },
    {
        "code": "ki",
        "name": "kiribati",
        "phone": "686"
    },
    {
        "code": "km",
        "name": "comoros",
        "phone": "269"
    },
    {
        "code": "kn",
        "name": "saint kitts and nevis",
        "phone": "1-869"
    },
    {
        "code": "kp",
        "name": "korea, democratic people's republic of",
        "phone": "850"
    },
    {
        "code": "kr",
        "name": "korea, republic of",
        "phone": "82"
    },
    {
        "code": "kw",
        "name": "kuwait",
        "phone": "965"
    },
    {
        "code": "ky",
        "name": "cayman islands",
        "phone": "1-345"
    },
    {
        "code": "kz",
        "name": "kazakhstan",
        "phone": "7"
    },
    {
        "code": "la",
        "name": "lao people's democratic republic",
        "phone": "856"
    },
    {
        "code": "lb",
        "name": "lebanon",
        "phone": "961"
    },
    {
        "code": "lc",
        "name": "saint lucia",
        "phone": "1-758"
    },
    {
        "code": "li",
        "name": "liechtenstein",
        "phone": "423"
    },
    {
        "code": "lk",
        "name": "sri lanka",
        "phone": "94"
    },
    {
        "code": "lr",
        "name": "liberia",
        "phone": "231"
    },
    {
        "code": "ls",
        "name": "lesotho",
        "phone": "266"
    },
    {
        "code": "lt",
        "name": "lithuania",
        "phone": "370"
    },
    {
        "code": "lu",
        "name": "luxembourg",
        "phone": "352"
    },
    {
        "code": "lv",
        "name": "latvia",
        "phone": "371"
    },
    {
        "code": "ly",
        "name": "libya",
        "phone": "218"
    },
    {
        "code": "ma",
        "name": "morocco",
        "phone": "212"
    },
    {
        "code": "mc",
        "name": "monaco",
        "phone": "377"
    },
    {
        "code": "md",
        "name": "moldova, republic of",
        "phone": "373"
    },
    {
        "code": "me",
        "name": "montenegro",
        "phone": "382"
    },
    {
        "code": "mf",
        "name": "saint martin (french part)",
        "phone": "590"
    },
    {
        "code": "mg",
        "name": "madagascar",
        "phone": "261"
    },
    {
        "code": "mh",
        "name": "marshall islands",
        "phone": "692"
    },
    {
        "code": "mk",
        "name": "macedonia, the former yugoslav republic of",
        "phone": "389"
    },
    {
        "code": "ml",
        "name": "mali",
        "phone": "223"
    },
    {
        "code": "mm",
        "name": "myanmar",
        "phone": "95"
    },
    {
        "code": "mn",
        "name": "mongolia",
        "phone": "976"
    },
    {
        "code": "mo",
        "name": "macao",
        "phone": "853"
    },
    {
        "code": "mp",
        "name": "northern mariana islands",
        "phone": "1-670"
    },
    {
        "code": "mq",
        "name": "martinique",
        "phone": "596"
    },
    {
        "code": "mr",
        "name": "mauritania",
        "phone": "222"
    },
    {
        "code": "ms",
        "name": "montserrat",
        "phone": "1-664"
    },
    {
        "code": "mt",
        "name": "malta",
        "phone": "356"
    },
    {
        "code": "mu",
        "name": "mauritius",
        "phone": "230"
    },
    {
        "code": "mv",
        "name": "maldives",
        "phone": "960"
    },
    {
        "code": "mw",
        "name": "malawi",
        "phone": "265"
    },
    {
        "code": "mx",
        "name": "mexico",
        "phone": "52"
    },
    {
        "code": "my",
        "name": "malaysia",
        "phone": "60"
    },
    {
        "code": "mz",
        "name": "mozambique",
        "phone": "258"
    },
    {
        "code": "na",
        "name": "namibia",
        "phone": "264"
    },
    {
        "code": "nc",
        "name": "new caledonia",
        "phone": "687"
    },
    {
        "code": "ne",
        "name": "niger",
        "phone": "227"
    },
    {
        "code": "nf",
        "name": "norfolk island",
        "phone": "672"
    },
    {
        "code": "ng",
        "name": "nigeria",
        "phone": "234"
    },
    {
        "code": "ni",
        "name": "nicaragua",
        "phone": "505"
    },
    {
        "code": "nl",
        "name": "netherlands",
        "phone": "31"
    },
    {
        "code": "no",
        "name": "norway",
        "phone": "47"
    },
    {
        "code": "np",
        "name": "nepal",
        "phone": "977"
    },
    {
        "code": "nr",
        "name": "nauru",
        "phone": "674"
    },
    {
        "code": "nu",
        "name": "niue",
        "phone": "683"
    },
    {
        "code": "nz",
        "name": "new zealand",
        "phone": "64"
    },
    {
        "code": "om",
        "name": "oman",
        "phone": "968"
    },
    {
        "code": "pa",
        "name": "panama",
        "phone": "507"
    },
    {
        "code": "pe",
        "name": "peru",
        "phone": "51"
    },
    {
        "code": "pf",
        "name": "french polynesia",
        "phone": "689"
    },
    {
        "code": "pg",
        "name": "papua new guinea",
        "phone": "675"
    },
    {
        "code": "ph",
        "name": "philippines",
        "phone": "63"
    },
    {
        "code": "pk",
        "name": "pakistan",
        "phone": "92"
    },
    {
        "code": "pl",
        "name": "poland",
        "phone": "48"
    },
    {
        "code": "pm",
        "name": "saint pierre and miquelon",
        "phone": "508"
    },
    {
        "code": "pn",
        "name": "pitcairn",
        "phone": "870"
    },
    {
        "code": "pr",
        "name": "puerto rico",
        "phone": "1"
    },
    {
        "code": "ps",
        "name": "palestine, state of",
        "phone": "970"
    },
    {
        "code": "pt",
        "name": "portugal",
        "phone": "351"
    },
    {
        "code": "pw",
        "name": "palau",
        "phone": "680"
    },
    {
        "code": "py",
        "name": "paraguay",
        "phone": "595"
    },
    {
        "code": "qa",
        "name": "qatar",
        "phone": "974"
    },
    {
        "code": "re",
        "name": "reunion",
        "phone": "262"
    },
    {
        "code": "ro",
        "name": "romania",
        "phone": "40"
    },
    {
        "code": "rs",
        "name": "serbia",
        "phone": "381"
    },
    {
        "code": "ru",
        "name": "russian federation",
        "phone": "7"
    },
    {
        "code": "rw",
        "name": "rwanda",
        "phone": "250"
    },
    {
        "code": "sa",
        "name": "saudi arabia",
        "phone": "966"
    },
    {
        "code": "sb",
        "name": "solomon islands",
        "phone": "677"
    },
    {
        "code": "sc",
        "name": "seychelles",
        "phone": "248"
    },
    {
        "code": "sd",
        "name": "sudan",
        "phone": "249"
    },
    {
        "code": "se",
        "name": "sweden",
        "phone": "46"
    },
    {
        "code": "sg",
        "name": "singapore",
        "phone": "65"
    },
    {
        "code": "sh",
        "name": "saint helena",
        "phone": "290"
    },
    {
        "code": "si",
        "name": "slovenia",
        "phone": "386"
    },
    {
        "code": "sj",
        "name": "svalbard and jan mayen",
        "phone": "47"
    },
    {
        "code": "sk",
        "name": "slovakia",
        "phone": "421"
    },
    {
        "code": "sl",
        "name": "sierra leone",
        "phone": "232"
    },
    {
        "code": "sm",
        "name": "san marino",
        "phone": "378"
    },
    {
        "code": "sn",
        "name": "senegal",
        "phone": "221"
    },
    {
        "code": "so",
        "name": "somalia",
        "phone": "252"
    },
    {
        "code": "sr",
        "name": "suriname",
        "phone": "597"
    },
    {
        "code": "ss",
        "name": "south sudan",
        "phone": "211"
    },
    {
        "code": "st",
        "name": "sao tome and principe",
        "phone": "239"
    },
    {
        "code": "sv",
        "name": "el salvador",
        "phone": "503"
    },
    {
        "code": "sx",
        "name": "sint maarten (dutch part)",
        "phone": "1-721"
    },
    {
        "code": "sy",
        "name": "syrian arab republic",
        "phone": "963"
    },
    {
        "code": "sz",
        "name": "swaziland",
        "phone": "268"
    },
    {
        "code": "tc",
        "name": "turks and caicos islands",
        "phone": "1-649"
    },
    {
        "code": "td",
        "name": "chad",
        "phone": "235"
    },
    {
        "code": "tf",
        "name": "french southern territories",
        "phone": "262"
    },
    {
        "code": "tg",
        "name": "togo",
        "phone": "228"
    },
    {
        "code": "th",
        "name": "thailand",
        "phone": "66"
    },
    {
        "code": "tj",
        "name": "tajikistan",
        "phone": "992"
    },
    {
        "code": "tk",
        "name": "tokelau",
        "phone": "690"
    },
    {
        "code": "tl",
        "name": "timor-leste",
        "phone": "670"
    },
    {
        "code": "tm",
        "name": "turkmenistan",
        "phone": "993"
    },
    {
        "code": "tn",
        "name": "tunisia",
        "phone": "216"
    },
    {
        "code": "to",
        "name": "tonga",
        "phone": "676"
    },
    {
        "code": "tr",
        "name": "turkey",
        "phone": "90"
    },
    {
        "code": "tt",
        "name": "trinidad and tobago",
        "phone": "1-868"
    },
    {
        "code": "tv",
        "name": "tuvalu",
        "phone": "688"
    },
    {
        "code": "tw",
        "name": "taiwan, province of china",
        "phone": "886"
    },
    {
        "code": "tz",
        "name": "united republic of tanzania",
        "phone": "255"
    },
    {
        "code": "ua",
        "name": "ukraine",
        "phone": "380"
    },
    {
        "code": "ug",
        "name": "uganda",
        "phone": "256"
    },
    {
        "code": "us",
        "name": "united states",
        "phone": "1"
    },
    {
        "code": "uy",
        "name": "uruguay",
        "phone": "598"
    },
    {
        "code": "uz",
        "name": "uzbekistan",
        "phone": "998"
    },
    {
        "code": "va",
        "name": "holy see (vatican city state)",
        "phone": "379"
    },
    {
        "code": "vc",
        "name": "saint vincent and the grenadines",
        "phone": "1-784"
    },
    {
        "code": "ve",
        "name": "venezuela",
        "phone": "58"
    },
    {
        "code": "vg",
        "name": "british virgin islands",
        "phone": "1-284"
    },
    {
        "code": "vi",
        "name": "us virgin islands",
        "phone": "1-340"
    },
    {
        "code": "vn",
        "name": "vietnam",
        "phone": "84"
    },
    {
        "code": "vu",
        "name": "vanuatu",
        "phone": "678"
    },
    {
        "code": "wf",
        "name": "wallis and futuna",
        "phone": "681"
    },
    {
        "code": "ws",
        "name": "samoa",
        "phone": "685"
    },
    {
        "code": "xk",
        "name": "kosovo",
        "phone": "383"
    },
    {
        "code": "ye",
        "name": "yemen",
        "phone": "967"
    },
    {
        "code": "yt",
        "name": "mayotte",
        "phone": "262"
    },
    {
        "code": "za",
        "name": "south africa",
        "phone": "27"
    },
    {
        "code": "zm",
        "name": "zambia",
        "phone": "260"
    },
    {
        "code": "zw",
        "name": "zimbabwe",
        "phone": "263"
    }
];

export default () => {
    const {state, dispatch} = useContext(context);
    const [userInfo, setUserInfo] = useState({
        first_name: state.authenticated_user.first_name,
        last_name: state.authenticated_user.last_name,
        gender: state.authenticated_user.gender ? state.authenticated_user.gender : "male",
        job_title: state.authenticated_user.job_title,
        email: state.authenticated_user.email,
        phone_number: state.authenticated_user.phone_number,
    });
    const [companyLogo, setCompanyLogo] = useState({
        preview: state.authenticated_user.company?.logo_url || null,
        file: null
    });
    const [companyInfo, setCompanyInfo] = useState({
        name: state.authenticated_user.company?.name || null,
        address: {
            street: state.authenticated_user.company?.address.street || null,
            city: state.authenticated_user.company?.address.city || null,
            state: state.authenticated_user.company?.address.state || null,
            zip_code: state.authenticated_user.company?.address.zip_code || null,
            country: _.find(countries, ['name', state.authenticated_user.company?.address.country || null])
        },
        tax_id: state.authenticated_user.company?.tax_id || null,
        email: state.authenticated_user.company?.email || null,
        phone_number: state.authenticated_user.company?.phone_number || null,
        website: state.authenticated_user.company?.website || null,
        description: state.authenticated_user.company?.description || null,
    });

    useEffect(async () => {
    }, [state.authenticated_user]);

    const [changePasswordData, setChangePasswordData] = useState({
        current_password: "",
        new_password: "",
        confirm_password: ""
    });

    const [openCroppingModal, setOpenCroppingModal] = useState(false);

    const [croppingData, setCroppingData] = useState({
        preview: null,
        file: null
    });

    const [changeCertificateData, setChangeCertificateData] = useState({
        new_certificate: ""
    });

    const [crop, setCrop] = useState({
        unit: 'px',
        x: 0, y: 0,
        width: 200, height: 200
    });

    const _renderCroppingModal = () => {
        if(!openCroppingModal) { return (<div />); }

        const dataURLToBlob = (dataURL) => {
            let BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) === -1) {
                let parts = dataURL.split(',');
                let contentType = parts[0].split(':')[1];
                let raw = parts[1];

                return new Blob([raw], {type: contentType});
            }

            let parts = dataURL.split(BASE64_MARKER);
            let contentType = parts[0].split(':')[1];
            let raw = window.atob(parts[1]);
            let rawLength = raw.length;

            let uInt8Array = new Uint8Array(rawLength);

            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: contentType});
        }

        const _submit = async (e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            let reader = new FileReader();
            reader.onload = function (readerEvent) {
                let image = new Image();
                image.onload = function (imageEvent) {
                    // Resize the image
                    let canvas = document.createElement('canvas');
                    let context = canvas.getContext('2d');
                    canvas.width = 400;
                    canvas.height = 400;
                    context.drawImage(image,
                        crop.x*image.width/100, crop.y*image.height/100, crop.width*image.width/100, crop.height*image.height/100,
                        0, 0, canvas.width, canvas.height
                    );
                    let dataUrl = canvas.toDataURL('image/jpeg');
                    let blobData = dataURLToBlob(dataUrl);
                    setCompanyLogo({
                        preview: dataUrl,
                        file: blobData
                    });
                }
                image.src = readerEvent.target.result;
            }
            reader.readAsDataURL(croppingData.file);

            setTimeout(function () {
                setOpenCroppingModal(false);
                setCrop({
                    unit: 'px',
                    x: 0, y: 0,
                    width: 200, height: 200
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 1000);
        }

        return (
            <Modal
                id="modal-cropping-image"
                open={openCroppingModal}
                onClose={() => setOpenCroppingModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openCroppingModal}>
                    <Box className="modal-content">
                        <form onSubmit={_submit}>
                            <div className="header">
                                <h2 className="title">Change Your Avatar</h2>
                            </div>
                            <div className="form">
                                <ReactCrop
                                    className="react-crop-wrapper"
                                    crop={crop} aspect={1}
                                    keepSelection={true} minWidth={200}
                                    onChange={(pixelCrop, percentCrop) => {
                                        setCrop(percentCrop);
                                    }}
                                    onComplete={(pixelCrop, percentCrop) => {}}
                                >
                                    <img src={croppingData.preview} alt=""/>
                                </ReactCrop>
                            </div>
                            <div className="footer">
                                <Button variant="outlined" onClick={() => setOpenCroppingModal(false)}>Cancel</Button>
                                <Button variant="contained" type="submit">Crop</Button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        );
    }

    const _renderProfile = () => {

        const _selectAvatar = (e) => {
            let file = e.target.files[0];

            setCroppingData({
                preview: URL.createObjectURL(file),
                file: file
            });
            setOpenCroppingModal(true);
        }

        const _changePasswordData = (e) => {
            switch (e.target.name) {
                case "current_password":
                    setChangePasswordData({
                        ...changePasswordData,
                        current_password: e.target.value
                    });
                    break;
                case "new_password":
                    setChangePasswordData({
                        ...changePasswordData,
                        new_password: e.target.value
                    });
                    break;
                case "confirm_password":
                    setChangePasswordData({
                        ...changePasswordData,
                        confirm_password: e.target.value
                    });
                    if(e.target.value === changePasswordData.new_password) {
                        e.target.setCustomValidity("");
                    } else {
                        e.target.setCustomValidity("Please enter the same Password as above");
                    }
                    break;
                default:
                    break;
            }
        }

        const _submitCertificate = async (e) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            let file = e.target.files[0];

            let formData = new FormData();
            formData.append(
                "x509_certificate_file",
                file,
                file.name
            );
            const httpResponse = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/x509-certificate`,
                headers: {
                    'Authorization': `Bearer ${state.authenticated_user.access_token}`,
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                data: formData
            });
            let appResponse = httpResponse.data;
            if(appResponse.code === 200) {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'Certificate was added!',
                    }
                });

                setTimeout(function () {
                    window.location.replace('/profile');
                }, 2000);
            } else {
                setTimeout(function () {
                    dispatch({
                        type: "SHOW_GLOBAL_MESSAGE",
                        payload: { open: true, type: 'error', message: appResponse.message }
                    });
                    dispatch({ type: "HIDE_GLOBAL_LOADING" });
                }, 500);
            }
        }

        const _submitInfo = (e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            let CompanyData = new FormData();
            CompanyData.append("name", companyInfo.name);

            let companyAddress = companyInfo.address;
            companyAddress.country = companyInfo.address.country?.name || null;
            CompanyData.append("address", JSON.stringify(companyAddress));

            if(companyLogo.file) {
                CompanyData.append(
                    "company_logo",
                    companyLogo.file
                );
            }

            if(!_.isEmpty(companyInfo.tax_id)) {
                CompanyData.append("tax_id", companyInfo.tax_id);
            }
            if(!_.isEmpty(companyInfo.email)) {
                CompanyData.append("email", companyInfo.email);
            }
            if(!_.isEmpty(companyInfo.phone_number)) {
                CompanyData.append("phone_number", companyInfo.phone_number);
            }
            if(!_.isEmpty(companyInfo.website)) {
                CompanyData.append("website", companyInfo.website);
            }
            if(!_.isEmpty(companyInfo.description)) {
                CompanyData.append("description", companyInfo.description);
            }

            let UserData = {
                first_name: userInfo.first_name,
                last_name: userInfo.last_name,
                gender: userInfo.gender,
                job_title: userInfo.job_title,
                phone_number: userInfo.phone_number,
            };

            dispatch({ type: ProfileActions.REQUEST_UPDATE_COMPANY_INFO, payload: CompanyData });
            dispatch({ type: ProfileActions.REQUEST_UPDATE_USER_PROFILE, payload: UserData });
            dispatch({ type: ProfileActions.REQUEST_NEW_CERTIFICATE, payload: {x509_certificate_file: changeCertificateData.new_certificate}});
            setTimeout(function () {
                setChangeCertificateData({
                    new_certificate: ""
                });
            }, 1000);
        }

        const _submitPassword = (e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: ProfileActions.REQUEST_CHANGE_PASSWORD, payload: {current_password: changePasswordData.current_password, new_password: changePasswordData.new_password}});
            setTimeout(function () {
                setChangePasswordData({
                    current_password: "", new_password: "", confirm_password: ""
                });
            }, 1000);
        }

        return (
            <div>
                <form  onSubmit={_submitInfo}>
                    <div className="content company-info">
                        <div className = "wrapper">
                            <div className="left">
                                <div className="avatar">
                                    {
                                        companyLogo.preview ?
                                            <img src={companyLogo.preview} alt="" className="" />
                                            :
                                            <ApartmentIcon className="empty-avatar" />
                                    }
                                    <input type="file" accept="image/*" id="avatar-file-input" onChange={_selectAvatar} style={{display: "none"}} />
                                    <label htmlFor="avatar-file-input" className="edit">
                                        <EditIcon />
                                    </label>
                                </div>
                                <div className="info">
                                    <p className="name">
                                        {companyInfo.name}
                                    </p>
                                    <p className="email"><a href={companyInfo.website} target="_blank">{companyInfo.website}</a></p>
                                </div>
                            </div>
                            <div className="right">
                                <p className="title">Company Details</p>

                                <div className="field">
                                    <TextField
                                        className="input company_name"
                                        name="company_name"
                                        type="text"
                                        label="Company Name"
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(e) => setCompanyInfo({...companyInfo, name: e.target.value.toLowerCase()})}
                                        value={companyInfo.name}
                                        required
                                    />
                                </div>
                                <div className="field address">
                                    <TextField
                                        className="input street"
                                        name="street"
                                        label="Street Address"
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(e) => setCompanyInfo({...companyInfo, address: {...companyInfo.address, street: e.target.value.toLowerCase()}})}
                                        value={companyInfo.address.street}
                                        required
                                    />
                                </div>
                                <div className="field address">
                                    <TextField
                                        className="input city"
                                        name="city"
                                        label="City"
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(e) => setCompanyInfo({...companyInfo, address: {...companyInfo.address, city: e.target.value.toLowerCase()}})}
                                        value={companyInfo.address.city}
                                        required
                                    />
                                    <TextField
                                        className="input state"
                                        name="state"
                                        label="State"
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(e) => setCompanyInfo({...companyInfo, address: {...companyInfo.address, state: e.target.value.toLowerCase()}})}
                                        value={companyInfo.address.state}
                                        required
                                    />
                                </div>
                                <div className="field">
                                    <TextField
                                        className="input zip_code"
                                        name="zip_code"
                                        label="Zip Code"
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(e) => setCompanyInfo({...companyInfo, address: {...companyInfo.address, zip_code: e.target.value}})}
                                        value={companyInfo.address.zip_code}
                                        required
                                    />
                                    <Autocomplete
                                        className="input country"
                                        options={countries}
                                        onChange={(e, value) => setCompanyInfo({...companyInfo, address: {...companyInfo.address, country: value}})}
                                        value={companyInfo.address.country}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name || ''}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                {_.capitalize(option.name)} ({option.code.toUpperCase()}) +{option.phone}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                                required
                                            />
                                        )}
                                    />
                                </div>
                                <div className="field">
                                    <TextField
                                        className="input tax_id"
                                        name="tax_id"
                                        label="Tax ID"
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(e) => setCompanyInfo({...companyInfo, tax_id: e.target.value.toLowerCase()})}
                                        value={companyInfo.tax_id}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className = "wrapper">
                            <div className="column">
                                <div className="field">
                                    <TextField
                                        className="input email"
                                        name="email"
                                        type="email"
                                        label="Email"
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(e) => setCompanyInfo({...companyInfo, email: e.target.value.toLowerCase()})}
                                        value={companyInfo.email}
                                    />
                                    <MuiPhoneNumber
                                        className="input phone_number"
                                        name="phone_number"
                                        label="Phone Number"
                                        variant="outlined"
                                        autoComplete="off"
                                        defaultCountry={'se'}
                                        onChange={(value) => setCompanyInfo({...companyInfo, phone_number: value})}
                                        value={companyInfo.phone_number}
                                    />
                                </div>
                                <div className="field">
                                    <TextField
                                        className="input website"
                                        name="website"
                                        type="url"
                                        label="Website"
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(e) => setCompanyInfo({...companyInfo, website: e.target.value.toLowerCase()})}
                                        value={companyInfo.website}
                                    />
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <TextField
                                        className="input description"
                                        name="description"
                                        label="Company Description"
                                        variant="outlined"
                                        size="small"
                                        fullWidth={true}
                                        multiline
                                        autoComplete="off"
                                        onChange={(e) => setCompanyInfo({...companyInfo, description: e.target.value.toLowerCase()})}
                                        value={companyInfo.description}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content main-contact">
                        <div className = "wrapper">
                            <p className="title">Main Contact</p>

                            <div className="field">
                                <FormControl className="input gender">
                                    <InputLabel id="gender-input-label">Noun</InputLabel>
                                    <Select
                                        labelId="gender-input-label"
                                        className="input gender"
                                        name="gender"
                                        label="Noun"
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(e) => setUserInfo({...userInfo, gender: e.target.value.toLowerCase()})}
                                        value={userInfo.gender}
                                    >
                                        <MenuItem value="male">Mr</MenuItem>
                                        <MenuItem value="female">Ms</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    className="input first_name"
                                    name="first_name"
                                    type="text"
                                    label="First Name"
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={(e) => setUserInfo({...userInfo, first_name: e.target.value.toLowerCase()})}
                                    value={userInfo.first_name}
                                    required
                                />
                                <TextField
                                    className="input last_name"
                                    name="last_name"
                                    type="text"
                                    label="Last Name"
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={(e) => setUserInfo({...userInfo, last_name: e.target.value.toLowerCase()})}
                                    value={userInfo.last_name}
                                    required
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    className="input job_title"
                                    name="job_title"
                                    label="Job Title"
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={(e) => setUserInfo({...userInfo, job_title: e.target.value.toLowerCase()})}
                                    value={userInfo.job_title}
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    className="input email"
                                    name="email"
                                    type="email"
                                    label="Email"
                                    variant="outlined"
                                    autoComplete="off"
                                    value={state.authenticated_user.email}
                                    required
                                    disabled
                                />
                                <MuiPhoneNumber
                                    className="input phone_number"
                                    name="phone_number"
                                    label="Phone Number"
                                    variant="outlined"
                                    autoComplete="off"
                                    defaultCountry={'se'}
                                    onChange={(value) => setUserInfo({...userInfo, phone_number: value})}
                                    value={userInfo.phone_number}
                                />
                            </div>

                            <div className="upload">
                                <input type="file" accept="application/x-x509-ca-cert" id="x509-file" onChange={_submitCertificate} style={{display: "none"}} />
                                    <label htmlFor="x509-file">
                                        <Button className="button" component="span" variant="outlined">Upload your x.509 certificate</Button>
                                    </label>
                                    <p className="question"><a href="https://docs.confidentialcloud.io/" target="_blank">Don't know how to generate an x.509 Certificate?</a></p>
                            </div>
                        </div>
                    </div>

                    <div className="submit-change-profile">
                        <Button variant="contained"  type="submit" >Update profile</Button>
                    </div>
                </form>

                <form onSubmit={_submitPassword}>
                    <div className="content change-password">
                        <div className = "wrapper">
                            <p className="title">Change Password</p>

                            <div className="field">
                                <TextField
                                    className="input current_password"
                                    name="current_password"
                                    type="password"
                                    label="Current Password"
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={_changePasswordData}
                                    value={changePasswordData.current_password}
                                    required
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    className="input new_password"
                                    name="new_password"
                                    type="password"
                                    label="New Password"
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={_changePasswordData}
                                    value={changePasswordData.new_password}
                                    required
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    className="input confirm_password"
                                    name="confirm_password"
                                    type="password"
                                    label="Confirm New Password"
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={_changePasswordData}
                                    value={changePasswordData.confirm_password}
                                    required
                                />
                            </div>

                            <div className="submit-change-password">
                                <Button variant="contained"  type="submit">Update password</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    return (
        <AppLayout>
            <div id="profile-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/profile" className="disabled" >
                                <AccountCircleOutlinedIcon className="icon"/>
                                <span>Profile</span>
                            </Link>
                        </li>
                    </ul>
                </div>

                { _renderProfile() }
                { _renderCroppingModal() }

            </div>
        </AppLayout>
    );
};
