import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import _ from "lodash";

import AppLayout from "../../../components/layouts/app";
import {context} from "../../../context";
import PartnerActions from '../../../context/actions/partners';
import BusinessIcon from '@mui/icons-material/Business';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import ApartmentIcon from '@mui/icons-material/Apartment';

export default ({children}) => {
    const {state, dispatch} = useContext(context);
    const {collaboration_id} = useParams();

    useEffect(async () => {
        if(state.partners.detail.partner === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNER_DETAIL, payload: {collaborationId: collaboration_id} });
        } else {
            if(state.partners.detail.partner.collaboration._id !== collaboration_id) {
                dispatch({ type: PartnerActions.RESPONSE_GET_PARTNER_DETAIL_FAILED });
            }
        }
    }, [state.partners.detail.partner, collaboration_id]);

    const _collaborator = () => {
        if(_.isEmpty(state.partners.detail.partner?.collaboration)) {
            return 'unknown@example.com';
        }

        return (state.authenticated_user._id === state.partners.detail.partner.collaboration.user_id) ?
            state.partners.detail.partner.collaboration.collaborator_email
            :
            state.partners.detail.partner?.collaboration.user_email
    };

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const _renderConfirmationModal = () => {
        if(!openConfirmationModal) { return (<div />); }

        const _submit = async (e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            dispatch({ type: PartnerActions.REQUEST_REMOVE_PARTNERS, payload: {collaborationId: collaboration_id} });
        }

        return (
            <Modal
                id="partners-detail-confirmation-modal"
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openConfirmationModal}>
                    <Box className="modal-content">
                        <form onSubmit={_submit}>
                            <div className="header">
                                <h2 className="title">Remove this Collaboration?</h2>
                            </div>
                            <div className="content">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>Partner:</td>
                                        <td>{state.partners.detail.partner?.company.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Website:</td>
                                        <td><a href={state.partners.detail.partner?.company.website} target="_blank">{state.partners.detail.partner?.company.website}</a></td>
                                    </tr>
                                    <tr>
                                        <td>Address:</td>
                                        <td>{_companyAddress(state.partners.detail.partner?.company.address)}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone:</td>
                                        <td>{state.partners.detail.partner?.company.phone_number}</td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td>{state.partners.detail.partner?.company.email}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="footer">
                                <Button variant="outlined" onClick={() => setOpenConfirmationModal(false)}>Cancel</Button>
                                <Button variant="contained" type="submit" color="error">Remove this Partner</Button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        );
    }

    const _companyAddress = (companyAddress = {}) => {
        return `${companyAddress.street}, ${companyAddress.city}, ${companyAddress.state}, ${companyAddress.country} ${companyAddress.zip_code}`;
    }

    const _requestRemoveCollaboration = () => {
        setOpenConfirmationModal(true);
    }

    const _renderPartnerInformation = () => {
        if(_.isEmpty(state.partners.detail.partner)) {
            return (<div />);
        }

        return (
            <div className="information">
                <div className="brand">
                    <div className="logo">
                        {
                            state.partners.detail.partner.company.logo_url ?
                                <img src={state.partners.detail.partner.company.logo_url} alt=""/>
                                :
                                <ApartmentIcon />
                        }
                    </div>
                    <div className="name">
                        {state.partners.detail.partner.company.name}
                    </div>
                </div>
                <div className="general-info">
                    <h3 className="label">General Information</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>Email:</td>
                            <td>{state.partners.detail.partner.company.email}</td>
                        </tr>
                        <tr>
                            <td>Phone:</td>
                            <td>{state.partners.detail.partner.company.phone_number}</td>
                        </tr>
                        <tr>
                            <td>Address:</td>
                            <td className="capitalize">{_companyAddress(state.partners.detail.partner.company.address)}</td>
                        </tr>
                        <tr>
                            <td>Website:</td>
                            <td><a href={state.partners.detail.partner.company.website} target="_blank">{state.partners.detail.partner.company.website}</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="main-contact">
                    <h3 className="label">Main Contact</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>{state.partners.detail.partner.main_contact.gender === 'female' ? "Ms" : "Mr"}:</td>
                            <td className="capitalize">{state.partners.detail.partner.main_contact.first_name} {state.partners.detail.partner.main_contact.last_name}</td>
                        </tr>
                        <tr>
                            <td>Job Title:</td>
                            <td className="capitalize">{state.partners.detail.partner.main_contact.job_title}</td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>{state.partners.detail.partner.main_contact.email}</td>
                        </tr>
                        <tr>
                            <td>Phone:</td>
                            <td>{state.partners.detail.partner.main_contact.phone_number}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    return (
        <AppLayout>
            <div id="partners-detail">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/partners" >
                                <BusinessIcon className="icon"/>
                                <span>Partners</span>
                            </Link>
                        </li>
                        <li className="divider">/</li>
                        <li>{ _collaborator() }</li>
                    </ul>
                </div>

                <div className="card" id="partner-information">
                    <h2 className="card-header">Partner Information</h2>
                    <div className="partner">
                        { _renderPartnerInformation() }

                        <div className="actions">
                            <div className="remove-collaboration">
                                <div className="description">
                                    <strong>Remove this collaboration</strong>
                                    <p>Once you remove a partner, some shared assets can be loose, there is no going back. Please be certain.</p>
                                </div>
                                <div className="action">
                                    <Button onClick={() => _requestRemoveCollaboration()} color="error" variant="outlined" startIcon={<DeleteForeverIcon />}>Remove this collaboration</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {children}

                { _renderConfirmationModal() }
            </div>
        </AppLayout>
    );
};
