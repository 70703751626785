import React, {createContext} from 'react';

import middlewares from "./middlewares";
import reducers from "./reducers";

const initialState = {
    authenticated_user: null,
    global_loading: false,
    global_message: {
        open: false,
        type: "success", // success || warning || error
        message: "Hello"
    },
    applications: {
        index: null,
        detail: null
    },
    datasets: {
        index: null,
        detail: null
    },
    enclaves: null,
    attestations: {
        index: null,
        detail: null
    },
    collaborations: {
        index: null,
        detail: null
    },
    partners: {
        index: null,
        detail: {
            partner: null,
            shared: null,
            applications: null,
            datasets: null,
        }
    },
    results: null,
    notifications: null,
    logs: {
        index: null,
    },
    billing: null,
    companies: {
        search: {
            keyword: null,
            results: []
        }
    },
    dashboard: null
};
const context = createContext(initialState);
const { Provider, Consumer }  = context;

const StateProvider = ( { children } ) => {

    const useReducerWithMiddleware = (reducer, initialState, middlewareFns) => {
        const [state, dispatch] = React.useReducer(reducer, initialState);

        const dispatchWithMiddleware = async (action) => {
            dispatch(action);
            middlewareFns.forEach((middlewareFn) => middlewareFn(state, action, dispatch));
        };

        return [state, dispatchWithMiddleware];
    };

    const [state, dispatch] = useReducerWithMiddleware(reducers, initialState, middlewares);

    return (
        <Provider value={{state, dispatch}}>
            {children}
        </Provider>
    );
};

export { context, StateProvider }
