import Actions from '../actions/enclaves';

export default (state, action) => {

    switch(action.type) {
        case Actions.RESPONSE_GET_ENCLAVES_SUCCESS:
            return {
                items: state?.items ? [...state.items, ...action.payload.items] : action.payload.items,
                total: action.payload.total,
                page_size: action.payload.page_size,
                current_page: action.payload.current_page,
            }

        case Actions.RESPONSE_CREATE_NEW_ENCLAVE_SUCCESS:
            // useful for case: user create new enclave before the enclaves reducer be initialed
            return (state === null) ? null : {
                ...state,
                items: [
                    action.payload,
                    ...state.items
                ],
                total: state.total + 1
            }

        default:
            return state;
    }
};
