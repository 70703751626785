import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import _ from "lodash";

import AppLayout from "../../components/layouts/app";
import {context} from "../../context";
import CollaborationActions from '../../context/actions/collaborations';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BusinessIcon from '@mui/icons-material/Business';
import {Link} from "react-router-dom";

export default () => {
    const {state, dispatch} = useContext(context);

    useEffect(async () => {
        if(state.collaborations.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: CollaborationActions.REQUEST_GET_COLLABORATIONS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.collaborations.index, state.collaborations.detail]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let collaborationItems = _.get(state, 'collaborations.index.items', []);

    let totalRecord = collaborationItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);

    const _renderCollaboration = () => {
        // TODO: filter/sort data by type || status

        const _reviewCollaborationRequest = (collaboration_request_id) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            setOpenReviewingModal(true);
            dispatch({ type: CollaborationActions.REQUEST_GET_COLLABORATION_DETAIL, payload: collaboration_request_id });
        }

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listCollaborationItems = collaborationItems.filter(function (collaboration, index) {
            return index >= from && index <= to;
        }).map(function (collaboration) {
            let type = (state.authenticated_user._id === collaboration.user_id) ?
                <span className="type request_out"><ArrowUpwardIcon color="action" /> Outgoing</span>
                :
                <span className="type request_in"><ArrowDownwardIcon color="success" /> Incoming</span>;

            let actions = () => {
                if((state.authenticated_user._id === collaboration.collaborator_id) && (collaboration.status.toLowerCase() === 'waiting')) {
                    return (
                        <span className="actions">
                            <Button variant="outlined" onClick={() => _reviewCollaborationRequest(collaboration._id)}>Review</Button>
                        </span>
                    );
                }

                return <span className={`status ${collaboration.status.toLowerCase()}`}>{collaboration.status.toLowerCase()}</span>;
            };

            let company_name = collaboration.company.name;
            let main_contact = collaboration.collaborator.first_name + " " + collaboration.collaborator.last_name;
            let email = (state.authenticated_user._id === collaboration.user_id) ? collaboration.collaborator_email : collaboration.user_email;

            return (
                <tr  key={collaboration._id} className={(state.authenticated_user._id === collaboration.user_id) ? "request_out" : ""}>
                    <td>{company_name}</td>
                    <td>{main_contact}</td>
                    <td>{email}</td>
                    <td>{type}</td>
                    <td>{actions()}</td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Company Name</th>
                    <th>Main Contact</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                { listCollaborationItems }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.collaborations.index.items.length < state.collaborations.index.total)) {
                dispatch({ type: CollaborationActions.REQUEST_GET_COLLABORATIONS });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    const [openReviewingModal, setOpenReviewingModal] = useState(false);
    const _renderReviewingModal = () => {
        if(!openReviewingModal || _.isEmpty(state.collaborations.detail)) { return (<div />); }

        const _submitAction = (type, collaboration_request_id) => {
            const requestType = (type === 'accept') ? CollaborationActions.REQUEST_ACCEPT_COLLABORATION : CollaborationActions.REQUEST_REFUSE_COLLABORATION;

            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: requestType, payload: collaboration_request_id });
            setOpenReviewingModal(false);
        };

        const _companyAddress = (companyAddress = {}) => {
            return `${companyAddress.street}, ${companyAddress.city}, ${companyAddress.state}, ${companyAddress.country} ${companyAddress.zip_code}`;
        }

        return (
            <Modal
                id="modal-collaborations-review"
                open={openReviewingModal}
                onClose={() => setOpenReviewingModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openReviewingModal}>
                    <Box className="modal-content">
                        <div className="header">
                            <h2 className="title">You have a Collaboration Request</h2>
                        </div>
                        <div className="content">
                            <table>
                                <tbody>
                                <tr>
                                    <td>Partner:</td>
                                    <td>{state.collaborations.detail.company.name}</td>
                                </tr>
                                <tr>
                                    <td>Website:</td>
                                    <td>{state.collaborations.detail.company.website}</td>
                                </tr>
                                <tr>
                                    <td>Address:</td>
                                    <td>{ _companyAddress(state.collaborations.detail.company.address) }</td>
                                </tr>
                                <tr>
                                    <td>Phone:</td>
                                    <td>{state.collaborations.detail.company.phone_number}</td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td>{state.collaborations.detail.company.email}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="footer">
                            <Button variant="outlined" onClick={() => setOpenReviewingModal(false)}>Cancel</Button>
                            <Button onClick={() => _submitAction('accept', state.collaborations.detail.collaboration._id)} color="success" variant="outlined" startIcon={<CheckCircleOutlineIcon />}>Accept</Button>
                            <Button onClick={() => _submitAction('refuse', state.collaborations.detail.collaboration._id)} color="error" variant="outlined" startIcon={<HighlightOffIcon />}>Refuse</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        );
    }

    return (
        <AppLayout>
            <div id="collaborations-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/partners" >
                                <BusinessIcon className="icon"/>
                                <span>Partners</span>
                            </Link>
                        </li>
                        <li className="divider">/</li>
                        <li>Collaborations</li>
                    </ul>
                </div>

                <div className="card">
                    { _renderCollaboration() }
                    { _renderPagination() }

                    { _renderReviewingModal() }
                </div>
            </div>
        </AppLayout>
    );
};
