
import Actions from '../actions/results';

export default (state, action) => {

    switch(action.type) {
        case Actions.RESPONSE_GET_RESULTS_SUCCESS:
            return (action.payload === null) ? null : {
                items: state?.items ? [...state.items, ...action.payload.items] : action.payload.items,
                total: action.payload.total,
                page_size: action.payload.page_size,
                current_page: action.payload.current_page,
            }

        default:
            return state;
    }
};
