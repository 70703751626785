import './detail.scss';
import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AppLayout from "../../components/layouts/app";
import {context} from "../../context";
import AttestationActions from '../../context/actions/attestations';
import _ from "lodash";

export default () => {

    const {state, dispatch} = useContext(context);
    const {attestation_id} = useParams();

    useEffect(async () => {
        if(state.attestations.detail === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: AttestationActions.REQUEST_GET_ATTESTATION_DETAIL, payload: {id: attestation_id} });
        } else {
            if(state.attestations.detail._id !== attestation_id) {
                dispatch({ type: AttestationActions.RESPONSE_GET_ATTESTATION_DETAIL_FAILED });
            } else {
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }
        }
    }, [state.attestations.detail, attestation_id]);

    const _attestationName = () => {
        return (_.isEmpty(state.attestations.detail?.name)) ? attestation_id : state.attestations.detail.name;
    };

    const _renderInformation = () => {
        if(_.isEmpty(state.attestations.detail)) {
            return (<div />);
        }

        let rows = [];
        _.forIn(state.attestations.detail, function(value, key) {
            rows.push(
                <tr>
                    <td>{key.replaceAll('_', " ")}</td>
                    <td>{(typeof(value) !== 'object') ? value : <pre>{JSON.stringify(value, undefined, 4)}</pre>}</td>
                </tr>
            );
        });

        return(
            <table>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    return (
        <AppLayout>
            <div id="attestation-detail">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/attestations" >
                                <VerifiedUserIcon className="icon"/>
                                <span>Attestations</span>
                            </Link>
                        </li>
                        <li className="divider">/</li>
                        <li>{ _attestationName() }</li>
                    </ul>
                </div>

                <div className="card">
                    <h3 className="card-header">Attestation's Information</h3>

                    { _renderInformation() }
                </div>
            </div>
        </AppLayout>
    );
}
