import Actions from '../actions/attestations';

export default (state, action) => {

    switch(action.type) {
        case Actions.RESPONSE_GET_ATTESTATIONS_SUCCESS:
            return (action.payload === null) ?
                { index: null, detail: null } :
                {
                    ...state,
                    index: {
                        items: state?.index?.items ? [...state.index.items, ...action.payload.items] : action.payload.items,
                        total: action.payload.total,
                        page_size: action.payload.page_size,
                        current_page: action.payload.current_page,
                    },
                }

        case Actions.RESPONSE_GET_ATTESTATION_DETAIL_SUCCESS:
            return {
                ...state,
                detail: action.payload
            };

        case Actions.RESPONSE_GET_ATTESTATION_DETAIL_FAILED:
            return {
                ...state,
                detail: null
            };

        default:
            return state;
    }
};
