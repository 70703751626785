import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import _ from "lodash";

import AppLayout from "../../components/layouts/app";
import {context} from "../../context";
import BillingActions from '../../context/actions/billing';
import {Link} from "react-router-dom";
import PaymentsIcon from '@mui/icons-material/Payments';
import moment from "moment";

export default () => {
    const {state, dispatch} = useContext(context);

    useEffect(async () => {
        if(state.billing === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: BillingActions.REQUEST_GET_BILLING });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.billing]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let billingItems = _.get(state, 'billing.items', []);
    let totalRecord = billingItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);
    const _renderBilling = () => {

        const _convertDuration = (durationInSecond) => {
            let h = Math.floor(durationInSecond / 3600);
            let m = Math.floor((durationInSecond % 3600) / 60);
            let s = (durationInSecond % 3600) % 60;

            let label = '';
            label += h ? `${h} hours ` : '';
            label += m ? `${m} minutes ` : '';
            label += `${s} seconds`;

            return label;
        }

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listBilling = billingItems.filter(function (billing, index) {
            return index >= from && index <= to;
        }).map(function (billing) {
            return (
                <tr key={billing._id}>
                    <td>{moment(billing.created_at).format("YYYY/MM/DD HH:mm:ss")}</td>
                    <td>
                        <Link to={`/enclaves/${billing.enclave_id}`}>{billing.enclave_id}</Link>
                    </td>
                    <td>{billing.cloud_provider}</td>
                    <td>{billing.cloud_region}</td>
                    <td>{_convertDuration(billing.duration)}</td>
                    <td>{`$${billing.cost}`}</td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Created on</th>
                    <th>Enclave ID</th>
                    <th>Cloud Provider</th>
                    <th>Region</th>
                    <th>Duration</th>
                    <th>Preliminary Cost</th>
                </tr>
                </thead>
                <tbody>
                { listBilling }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.billing.items.length < state.billing.total)) {
                dispatch({ type: BillingActions.REQUEST_GET_BILLING });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    return (
        <AppLayout>
            <div id="billing-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/billing" className="disabled" >
                                <PaymentsIcon className="icon"/>
                                <span>Billing</span>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="card">
                    { _renderBilling() }

                    { _renderPagination() }
                </div>
            </div>
        </AppLayout>
    );
};
