import axios from "axios";
import _ from "lodash";

import Actions from '../actions/companies';

export default async (state, action, dispatch) => {
    switch(action.type) {
        case Actions.REQUEST_SEARCH_COMPANIES:
            await _searchCompanies(state, action, dispatch);
            break;
    }
}

const _searchCompanies = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/companies`,
            params: {
                keyword: action.payload.keyword
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        dispatch({
            type: Actions.RESPONSE_SEARCH_COMPANIES_SUCCESS,
            payload: {
                keyword: action.payload.keyword,
                results: appResponse.data
            }
        });
        if(appResponse.data.length === 0) {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'warning', message: "No data matching!" }
            });
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};
