import './create.scss';
import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";
import AppLayout from "../../components/layouts/app";
import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {context} from "../../context";
import EnclaveActions from '../../context/actions/enclaves';
import ApplicationActions from '../../context/actions/applications';
import DatasetActions from '../../context/actions/datasets';
import _ from "lodash";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";

export default () => {

    const navigate  = useNavigate();
    const {state, dispatch} = useContext(context);
    const [newEnclave, setNewEnclave] = useState({ cloud_provider: 'ms_azure', dataset: {}, application: {} });
    useEffect(async () => {
        if((state.applications.index === null) || (state.applications.index.items.length < state.applications.index.total)) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: ApplicationActions.REQUEST_GET_APPLICATIONS });
        } else if((state.datasets.index === null) || (state.datasets.index.items.length < state.datasets.index.total)) {
            dispatch({ type: DatasetActions.REQUEST_GET_DATASETS });
        } else {
            setNewEnclave({...newEnclave, dataset: state.datasets.index.items[0], application: state.applications.index.items[0]});
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }

    }, [state.applications.index, state.datasets.index]);

    const _renderForm = () => {

        const _submit = async (e) => {
            e.preventDefault();
            dispatch({type: "SHOW_GLOBAL_LOADING"});
            dispatch({
                type: EnclaveActions.REQUEST_CREATE_NEW_ENCLAVE,
                payload: { cloud_provider: newEnclave.cloud_provider, dataset_id: newEnclave.dataset._id, application_id: newEnclave.application._id }
            });

            // for temporary
            setTimeout(function () {
                setNewEnclave({ cloud_provider: '', dataset: {}, application: {} });
                dispatch({type: "HIDE_GLOBAL_LOADING"});
                navigate ('/enclaves');
            }, 500);
        };

        if((state.datasets.index === null) || (state.applications.index === null)) {
            return (<div />);
        }

        const _renderCloudRegionLabel = () => {
            return (
                <span className="cloud-region-label">
                    <img src="https://flagcdn.com/w20/se.png" alt=""/>
                    Gävle (swedencentral)
                </span>
            );
        }
        return (
            <form className="card" onSubmit={_submit}>
                <h3 className="card-header">Run a new Enclave</h3>

                <div className="form">
                    <div className="computer">
                        <div className="cloud-providers">
                            <FormControl className="provider" required={true}>
                                <FormLabel>Cloud Provider & Region</FormLabel>
                                <RadioGroup
                                    name="cloud-provider"
                                    value={newEnclave.cloud_provider}
                                    onChange={(e) => {setNewEnclave({...newEnclave, cloud_provider: e.target.value})}}
                                >
                                    <FormControlLabel value="ms_azure" control={<Radio required={true} />} label="MS Azure" />
                                    <FormControlLabel disabled={true} value="ovh" control={<Radio disabled={true} />} label="OVH" />
                                    <FormControlLabel disabled={true} value="aws" control={<Radio required={true} />} label="AWS" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div className="cloud-regions">
                            <FormControl className="regions">
                                <FormLabel>Europe</FormLabel>
                                <RadioGroup name="cloud-region" value="swedencentral">
                                    <FormControlLabel
                                        value="swedencentral" control={<Radio />}
                                        label={_renderCloudRegionLabel()}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>

                    <div className="assets">
                        <FormControl fullWidth className="dataset">
                            <FormLabel >Select the Dataset</FormLabel>
                            <Autocomplete
                                id="input_datasets"
                                disablePortal
                                options={state.datasets.index.items}
                                getOptionLabel={(option) => option.name || ''}
                                value={newEnclave.dataset}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(event, value) => {setNewEnclave({...newEnclave, dataset: value})}}
                                renderInput={(params) => <TextField {...params} required={true} placeholder="Type to search ..." />}
                            />
                        </FormControl>
                        <FormControl fullWidth className="application">
                            <FormLabel>Select the Application</FormLabel>
                            <Autocomplete
                                id="input_applications"
                                disablePortal
                                options={state.applications.index.items}
                                getOptionLabel={(option) => option.name || ''}
                                value={newEnclave.application}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(event, value) => {setNewEnclave({...newEnclave, application: value})}}
                                renderInput={(params) => <TextField {...params} required={true} placeholder="Type to search ..." />}
                            />
                        </FormControl>
                    </div>
                </div>

                <div className="footer">
                    <Button variant="outlined"
                            onClick={() => {
                                dispatch({ type: "SHOW_GLOBAL_LOADING" });
                                setTimeout(function () {
                                    window.location.href = "/enclaves";
                                }, 500);
                            }}
                    >Cancel</Button>
                    <Button variant="contained" type="submit">RUN</Button>
                </div>
            </form>
        );
    }

    return (
        <AppLayout>
            <div id="enclaves-creating">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/enclaves" >
                                <FeaturedVideoIcon className="icon"/>
                                <span>Enclaves</span>
                            </Link>
                        </li>
                        <li className="divider">/</li>
                        <li>Run new Enclave</li>
                    </ul>
                </div>

                { _renderForm() }
            </div>
        </AppLayout>
    );
}
