import axios from "axios";
import _ from "lodash";

import Actions from '../actions/applications';

export default async (state, action, dispatch) => {
    switch(action.type) {
        case Actions.REQUEST_GET_APPLICATIONS:
            await _getApplications(state, action, dispatch);
            break;

        case Actions.REQUEST_GET_APPLICATION_DETAIL:
            await _getApplication(state, action, dispatch);
            break;

        case Actions.REQUEST_CREATE_NEW_APPLICATION:
            await _createApplication(state, action, dispatch);
            break;

        case Actions.REQUEST_DELETE_APPLICATION:
            await _deleteApplication(state, action, dispatch);
            break;
    }
}

const _getApplications = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/applications`,
            params: {
                page: _.get(state, 'applications.current_page', 0) + 1
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        dispatch({ type: Actions.RESPONSE_GET_APPLICATIONS_SUCCESS, payload: appResponse.data });
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }

}

const _getApplication = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/applications/${action.payload.id}`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
        });

        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_GET_APPLICATION_DETAIL_SUCCESS, payload: appResponse.data });
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
}

const _createApplication = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/applications`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
            data: action.payload
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            setTimeout(function () {
                dispatch({ type: Actions.RESPONSE_CREATE_NEW_APPLICATION_SUCCESS, payload: appResponse.data });
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'New application was added!',
                    }
                });
            }, 500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }

}

const _deleteApplication = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/applications/${action.payload.id}`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
        });

        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            setTimeout(function () {
                dispatch({ type: Actions.RESPONSE_DELETE_APPLICATION_SUCCESS, payload: {id: action.payload.id} });
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'Application was deleted!',
                    }
                });
            }, 500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
}

