import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select  from '@mui/material/Select';

import AppLayout from "../../components/layouts/app";

import {context} from "../../context";
import ApplicationActions from '../../context/actions/applications';
import _ from "lodash";
import {Link} from "react-router-dom";
import CodeIcon from "@mui/icons-material/Code";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default () => {
    const {state, dispatch} = useContext(context);

    useEffect(async () => {
        if(state.applications.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: ApplicationActions.REQUEST_GET_APPLICATIONS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
            setNewApplication({
                name: "", description: "",
                status: "private",
                public_endpoint: "", owner: "",
                s3_endpoint: "", s3_file_uri: "", s3_policy_name: "", s3_result_uri: ""
            });
            setOpenCreatingModal(false);
            setDeletingApplication({});
            setOpenDeletingModal(false);
        }
    }, [state.applications.index]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let applicationItems = _.get(state, 'applications.index.items', []);
    let totalRecord = applicationItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);
    const _renderApplications = () => {

        const _deleteApplication = (app) => {
            setOpenDeletingModal(true);
            setDeletingApplication(app);
        }

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listApplications = applicationItems.filter(function (application, index) {
            return index >= from && index <= to;
        }).map(function (application) {
            return (
                <tr key={application._id}>
                    <td>{application.name}</td>
                    <td>{application.owner}</td>
                    <td>{application.status}</td>
                    <td>{application.public_endpoint || application.s3_endpoint }</td>
                    <td>{application.s3_policy_name}</td>
                    <td>
                        <Link to={`/applications/${application._id}`} className="link"><VisibilityIcon sx={{ color: 'rgb(25, 118, 210)' }} /></Link>
                        <span className='delete' onClick={() => _deleteApplication(application)}>
                            <DeleteIcon  sx={{ color: 'rgb(239, 83, 80)' }} />
                        </span>
                    </td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Status</th>
                    <th>Endpoint</th>
                    <th>Policy Name</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { listApplications }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.applications.index.items.length < state.applications.index.total)) {
                dispatch({ type: ApplicationActions.REQUEST_GET_APPLICATIONS });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    const [openCreatingModal, setOpenCreatingModal] = useState(false);
    const [newApplication, setNewApplication] = useState(
        {
            name: "", description: "",
            status: "private",
            public_endpoint: "", owner: "",
            s3_endpoint: "", s3_file_uri: "", s3_policy_name: "", s3_result_uri: ""
        }
    );
    const _renderCreatingModal = () => {
        if(!openCreatingModal) { return (<div />); }

        const _renderResourceEndpoint = () => {
            if(newApplication.status === 'private') {
                return (
                    <div className="endpoint">
                        <TextField
                            id="input-s3_endpoint"
                            required
                            label="S3 Endpoint"
                            name="s3_endpoint"
                            size="small"
                            fullWidth={true}
                            placeholder="https://minio.confidentialcloud.io"
                            value={newApplication.s3_endpoint}
                            onChange={(e) => {setNewApplication({...newApplication, s3_endpoint: e.target.value})}}
                            onFocus={(e) => {
                                if(_.isEmpty(e.target.value)) {
                                    setNewApplication({...newApplication, s3_endpoint: "https://"});
                                }
                            }}
                            onBlur={(e) => {
                                if(e.target.value === "https://") {
                                    setNewApplication({...newApplication, s3_endpoint: ""});
                                }
                            }}
                        />
                        <TextField
                            id="input-s3_file_uri"
                            required
                            label="S3 File URI"
                            name="s3_file_uri"
                            size="small"
                            fullWidth={true}
                            placeholder="s3://my-bucket/my-object"
                            value={newApplication.s3_file_uri}
                            onChange={(e) => {setNewApplication({...newApplication, s3_file_uri: e.target.value, s3_result_uri: `${e.target.value}-results`})}}
                            onFocus={(e) => {
                                if(_.isEmpty(e.target.value)) {
                                    setNewApplication({...newApplication, s3_file_uri: "s3://"});
                                }
                            }}
                            onBlur={(e) => {
                                if(e.target.value === "s3://") {
                                    setNewApplication({...newApplication, s3_file_uri: ""});
                                }
                            }}
                        />
                        <TextField
                            id="input-s3_policy_name"
                            required
                            label="S3 Policy Name"
                            name="s3_policy_name"
                            size="small"
                            fullWidth={true}
                            placeholder="my-policy-name"
                            value={newApplication.s3_policy_name}
                            onChange={(e) => {setNewApplication({...newApplication, s3_policy_name: e.target.value})}}
                        />
                        <TextField
                            id="input-s3_result_uri"
                            required
                            label="S3 Result URI"
                            name="s3_result_uri"
                            size="small"
                            fullWidth={true}
                            placeholder="s3://s3-bucket-result"
                            value={newApplication.s3_result_uri}
                            onChange={(e) => {setNewApplication({...newApplication, s3_result_uri: e.target.value})}}
                            onFocus={(e) => {
                                if(_.isEmpty(e.target.value)) {
                                    setNewApplication({...newApplication, s3_result_uri: "s3://"});
                                }
                            }}
                            onBlur={(e) => {
                                if(e.target.value === "s3://") {
                                    setNewApplication({...newApplication, s3_result_uri: ""});
                                }
                            }}
                        />
                    </div>
                );
            }

            return (
                <div className="endpoint">
                    <TextField
                        id="input-public_endpoint"
                        required
                        label="Public Endpoint"
                        name="public_endpoint"
                        size="small"
                        fullWidth={true}
                        value={newApplication.public_endpoint}
                        onChange={(e) => {setNewApplication({...newApplication, public_endpoint: e.target.value})}}
                        onFocus={(e) => {
                            if(_.isEmpty(e.target.value)) {
                                setNewApplication({...newApplication, public_endpoint: "https://"});
                            }
                        }}
                        onBlur={(e) => {
                            if(e.target.value === "https://") {
                                setNewApplication({...newApplication, public_endpoint: ""});
                            }
                        }}
                    />
                    <TextField
                        id="input-owner"
                        required
                        label="Owner"
                        name="owner"
                        size="small"
                        fullWidth={true}
                        value={newApplication.owner}
                        onChange={(e) => {setNewApplication({...newApplication, owner: e.target.value})}}
                    />
                </div>
            );
        }

        const _submit = async (e) => {
            e.preventDefault();
            let data = _.cloneDeep(newApplication);
            if(_.isEmpty(data.description)) {
                delete data.description;
            }
            if(data.status === 'private') {
                delete data.public_endpoint;
                delete data.owner;
            } else {
                delete data.s3_endpoint;
                delete data.s3_file_uri;
                delete data.s3_policy_name;
                delete data.s3_result_uri;
            }
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: ApplicationActions.REQUEST_CREATE_NEW_APPLICATION, payload: {...data} });
        }

        return (
            <Modal
                id="modal-applications-create-new"
                open={openCreatingModal}
                onClose={() => setOpenCreatingModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openCreatingModal}>
                    <Box className="modal-content">
                        <form onSubmit={_submit}>
                            <div className="header">
                                <h2 className="title">Add New Application</h2>
                            </div>
                            <div className="form">
                                <TextField
                                    id="input-name"
                                    required
                                    label="Name"
                                    size="small"
                                    fullWidth={true}
                                    value={newApplication.name}
                                    onChange={(e) => {setNewApplication({...newApplication, name: e.target.value})}}
                                />
                                <TextField
                                    id="input-description"
                                    label="Description"
                                    size="small"
                                    fullWidth={true}
                                    multiline
                                    rows={2}
                                    value={newApplication.description}
                                    onChange={(e) => {setNewApplication({...newApplication, description: e.target.value})}}
                                />
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        id="input-status"
                                        required
                                        label="Status"
                                        value={newApplication.status}
                                        onChange={(e) => {setNewApplication({...newApplication, status: e.target.value})}}
                                    >
                                        <MenuItem value="public">Public</MenuItem>
                                        <MenuItem value="private">Private</MenuItem>
                                    </Select>
                                </FormControl>

                                { _renderResourceEndpoint() }
                            </div>
                            <div className="footer">
                                <Button variant="outlined" onClick={() => setOpenCreatingModal(false)} id="cancel-new-application-btn">Cancel</Button>
                                <Button variant="contained" type="submit" id="submit-new-application-btn">Submit</Button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        );
    }

    const [openDeletingModal, setOpenDeletingModal] = useState(false);
    const [deletingApplication, setDeletingApplication] = useState({});
    const _renderDeletingModal = () => {
        if(!openDeletingModal) { return (<div />); }

        const _renderAppDetail = () => {
            if((deletingApplication.status === 'private')) {
                return (
                    <table>
                        <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{deletingApplication.name}</td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>{deletingApplication.description}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{deletingApplication.status}</td>
                        </tr>
                        <tr>
                            <td>Owner</td>
                            <td>{deletingApplication.owner}</td>
                        </tr>
                        <tr>
                            <td>S3 Endpoint</td>
                            <td>{deletingApplication.s3_endpoint}</td>
                        </tr>
                        <tr>
                            <td>S3 File URI</td>
                            <td>{deletingApplication.s3_file_uri}</td>
                        </tr>
                        <tr>
                            <td>S3 Policy Name</td>
                            <td>{deletingApplication.s3_policy_name}</td>
                        </tr>
                        <tr>
                            <td>S3 Result URI</td>
                            <td>{deletingApplication.s3_result_uri}</td>
                        </tr>
                        </tbody>
                    </table>
                );
            }

            return (
                <table>
                    <tbody>
                    <tr>
                        <td>Name</td>
                        <td>{deletingApplication.name}</td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>{deletingApplication.description}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{deletingApplication.status}</td>
                    </tr>
                    <tr>
                        <td>Owner</td>
                        <td>{deletingApplication.owner}</td>
                    </tr>
                    <tr>
                        <td>Public Endpoint</td>
                        <td>{deletingApplication.public_endpoint}</td>
                    </tr>
                    </tbody>
                </table>
            );
        }

        const _submit = async (e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: ApplicationActions.REQUEST_DELETE_APPLICATION, payload: {id: deletingApplication._id} });
        }

        return (
            <Modal
                id="modal-applications-delete"
                open={openDeletingModal}
                onClose={() => setOpenDeletingModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openDeletingModal}>
                    <Box className="modal-content">
                        <form onSubmit={_submit}>
                            <div className="header">
                                <h2 className="title">Delete this Application?</h2>
                            </div>
                            <div className="content">
                                { _renderAppDetail() }
                            </div>
                            <div className="footer">
                                <Button variant="outlined" onClick={() => setOpenDeletingModal(false)}>Cancel</Button>
                                <Button variant="contained" type="submit" color="error">Delete it!</Button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        );
    }

    return (
        <AppLayout>
            <div id="applications-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/applications" className="disabled" >
                                <CodeIcon className="icon"/>
                                <span>Applications</span>
                            </Link>
                        </li>
                    </ul>

                    <Button id="add-new-application-btn" variant="outlined" onClick={() => setOpenCreatingModal(true)}>
                        Add New
                    </Button>
                </div>

                <div className="card">
                    { _renderApplications() }

                    { _renderPagination() }
                </div>

                { _renderCreatingModal() }
                { _renderDeletingModal() }
            </div>
        </AppLayout>
    );
};
