import GlobalLoadingReducers from './globalLoading';
import GlobalMessageReducers from './globalMessage';
import ProfileReducers from './profile';
import ApplicationReducers from './applications';
import DatasetReducers from './datasets';
import EnclaveReducers from './enclaves';
import AttestationReducers from './attestations';
import CollaborationReducers from './collaborations';
import PartnerReducers from './partners';
import ResultReducers from './results';
import NotificationReducers from './notifications';
import LogReducers from './logs';
import BillingReducers from './billing';
import CompaniesReducers from './companies';
import DashboardReducers from './dashboard';

const combineReducers = (reducers) => (state, action) => {
    // console.log("action: ", action);

    return Object.keys(reducers).reduce(
        (currentState, reducerName) => {
            return {
                ...currentState,
                [reducerName]: reducers[reducerName](currentState[reducerName], action)
            };
        },
        state
    );
}

const reducers = combineReducers({
    global_loading: GlobalLoadingReducers,
    global_message: GlobalMessageReducers,
    authenticated_user: ProfileReducers,
    applications: ApplicationReducers,
    datasets: DatasetReducers,
    enclaves: EnclaveReducers,
    attestations: AttestationReducers,
    collaborations: CollaborationReducers,
    partners: PartnerReducers,
    results: ResultReducers,
    notifications: NotificationReducers,
    logs: LogReducers,
    billing: BillingReducers,
    companies: CompaniesReducers,
    dashboard: DashboardReducers,
});

export default reducers;
