export default (state, action) => {
    switch(action.type) {
        case 'SHOW_GLOBAL_LOADING':
            return true

        case 'HIDE_GLOBAL_LOADING':
            return false

        default:
            return state;
    }
};
