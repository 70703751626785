import "./home.scss";
import React, {useEffect, useState, useContext} from 'react';
import _ from "lodash";

import AppLayout from "../../components/layouts/app";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";

import {context} from "../../context";
import DashboardActions from "../../context/actions/dashboard";

const Home = () => {
    const {state, dispatch} = useContext(context);

    useEffect(async () => {
        if(_.isEmpty(state.dashboard)) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({type: DashboardActions.REQUEST_GET_DASHBOARD_DATA});
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.dashboard]);

    const _renderDashboard = () => {
        let dashboardData = state.dashboard;

        if(_.isEmpty(dashboardData)) {
            return (<div />);
        }

        return (
            <div className="home">
                <div className="homeContainer">
                    <div className="widgets">
                        <Widget type="application" amount={dashboardData.applications}/>
                        <Widget type="dataset" amount={dashboardData.datasets}/>
                        <Widget type="enclave" amount={dashboardData.enclaves}/>
                        <Widget type="result" amount={dashboardData.results}/>
                    </div>
                    <div className="charts">
                        <Featured/>
                        <Chart title="Last 6 Months" aspect={2 / 1}/>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <AppLayout>
            { _renderDashboard() }
        </AppLayout>
    );
};

export default Home;
