import './x509_certificate.scss';
import React, {useEffect, useState, useContext} from 'react';

import GuestLayout from "../../../components/layouts/guest";
import {context} from "../../../context";
import Button from "@mui/material/Button";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import axios from "axios";

export default () => {

    const {state, dispatch} = useContext(context);

    const steps = [
        'Upload X.509 Certificate',
        'Update Company Profile',
        'Review',
    ];

    const _onSubmit = async (e) => {
        dispatch({ type: "SHOW_GLOBAL_LOADING" });
        let file = e.target.files[0];

        let formData = new FormData();
        formData.append(
            "x509_certificate_file",
            file,
            file.name
        );
        const httpResponse = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/x509-certificate`,
            headers: {
                'Authorization': `Bearer ${state.authenticated_user.access_token}`,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: formData
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: {
                    open: true,
                    type: 'success',
                    message: 'Certificate was added!',
                }
            });

            setTimeout(function () {
                window.location.replace('/');
            }, 2000);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    }

    const _logout = (e) => {
        e.preventDefault();
        dispatch({ type: "SHOW_GLOBAL_LOADING" });

        setTimeout(function () {
            window.localStorage.removeItem('access_token');
            window.location.replace('/signin');
        }, 1000);
    }

    return (
        <GuestLayout>
            <div id="x509-certificate">
                <div className="wrapper">
                    <div className="brand">
                        <img src="/confidential_cloud_studio.png" alt=""/>
                    </div>

                    <div className="stepper">
                        <Stepper activeStep={0} alternativeLabel={true}>
                            {steps.map((label) => (
                                <Step>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>

                    <div className="box">
                        <h2 className="title">Upload your X.509 Certificate</h2>
                        <p className="description">Confidential Cloud Studio is architected using strong security mechanisms and requires an x.509 certificate to secure the entire collaboration process.</p>
                        <p className="question"><a href="https://docs.confidentialcloud.io/x509_certificate/" target="_blank">Don't know how to generate an x.509 Certificate?</a></p>

                        <input type="file" accept="application/x-x509-ca-cert" id="x509-file" onChange={_onSubmit} style={{display: "none"}} />
                        <label htmlFor="x509-file">
                            <Button className="button" component="span" variant="outlined">upload from you computer</Button>
                        </label>
                    </div>
                    <div className="footer">
                        <p className="logout">Not Ready? <span onClick={_logout}>Logout!</span></p>
                    </div>
                </div>
            </div>
        </GuestLayout>
    );
}
