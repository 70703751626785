import './request.scss';
import React, {useEffect, useState, useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import _ from "lodash";

import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AppLayout from "../../../components/layouts/app";
import CollaborationActions from '../../../context/actions/collaborations'
import PartnerActions from '../../../context/actions/partners'
import LogActions from '../../../context/actions/logs'
import {context} from "../../../context";
import { ContactsOutlined } from '@mui/icons-material';

export default () => {
    const {state, dispatch} = useContext(context);
    const {log_id} = useParams();
    const this_page_collab_id = log_id

    useEffect(async () => {
        if(state.logs.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: LogActions.REQUEST_GET_LOGS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.logs.index]);

    useEffect(async () => {
        if(state.collaborations.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: CollaborationActions.REQUEST_GET_COLLABORATIONS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.collaborations.index]);

    useEffect(async () => {
        if(state.partners.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNERS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.partners.index]);

    let this_collab = null
    if(state.logs.index !== null){
        this_collab = state.logs.index.items.filter(function(item){
            return item._id === this_page_collab_id;
        })[0].collaboration_id
    }

    useEffect(async () => {
        if(state.partners.detail.applications === null && this_collab !== null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNER_APPLICATIONS, payload: {collaborationId: this_collab} });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    },[state.partners.detail.applications]);

    useEffect(async () => {
        if(state.partners.detail.datasets === null && this_collab !== null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNER_DATASETS, payload: {collaborationId: this_collab} });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.partners.detail.datasets]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let logItems = _.get(state, 'logs.index.items', []);

    if(logItems.length !== 0 && this_collab !==null){ //filtering to get only the request && belong to this collab
        logItems = logItems.filter(function(item){
            return (item.type === "request" && item.collaboration_id === this_collab)
        })
    }

    let totalRecord = logItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);

    const _renderLogsRequests = () => {
        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;

        const listLogs = logItems.filter(function (log, index) {
            return index >= from && index <= to;
        }).map(function (log) {
            
            let request_asset_name = (log.data !== undefined) ? 
                log.data.cached_name : "....." 

            let statuss = (log.data !== undefined) ?
                log.data.status : "......"  

            let started = (log.data !== undefined) ?
                log.data.start_date : "......"

            let ended = (log.data !== undefined) ?
                log.data.end_date : "....."

            let initiatedBy = (log.data !== undefined) ?
                log.data.initiated_by : "....."   

            let link = "/"
            if (log.data !== undefined){
                if (log.data.asset_type === "application"){
                    link = "/applications/" + log.asset_id;    
                }
                if (log.data.asset_type === "dataset"){
                    link = "/datasets/" + log.asset_id;    
                }
            }

            return ( 
                <tr key={log._id}>
                    <td><a href={link}>{request_asset_name}</a></td>
                    <td>{statuss}</td>
                    <td>{started}</td>
                    <td>{ended}</td>
                    <td>{initiatedBy}</td>
                    <td><Link to={`/logs/${this_page_collab_id}/asset/${log.asset_id}`} className="link"><VisibilityIcon sx={{ color: 'rgb(25, 118, 210)' }} /></Link></td> 
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Primary Asset</th>
                    <th>Status</th>
                    <th>Started</th>
                    <th>Ended</th>
                    <th>Requested By</th>
                    <th>inspect</th>
                </tr>
                </thead>
                <tbody>
                { listLogs }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.logs.index.items.length < state.logs.total)) {
                dispatch({ type: LogActions.REQUEST_GET_LOGS });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }
    const _log = () => {
        if(this_collab !== null){
            return (state.collaborations.index !== null) ?
            state.collaborations.index.items.filter(function(item){
                return item._id === this_collab;
            })[0].company.name : "......"
        }  
    };

    return (
        <AppLayout>
            <div id="partners-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/logs" >
                                <PsychologyOutlinedIcon className="icon"/>
                                <span>Audit Logs</span>
                            </Link>
                        </li>
                        <li className="divider">/</li>
                        <li>{_log()}</li>
                    </ul>


                </div>

                <div className="card">               
                    { _renderLogsRequests() }
                    { _renderPagination() }
                </div>

            </div>
        </AppLayout>
    );
};