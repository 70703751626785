import axios from "axios";
import _ from "lodash";

import Actions from '../actions/partners';

export default async (state, action, dispatch) => {
    switch(action.type) {
        case Actions.REQUEST_GET_PARTNERS:
            await _getPartners(state, action, dispatch);
            break;

        case Actions.REQUEST_GET_PARTNER_DETAIL:
            await _getPartnerDetail(state, action, dispatch);
            break;

        case Actions.REQUEST_GET_PARTNER_APPLICATIONS:
            await _getPartnerApplications(state, action, dispatch);
            break;

        case Actions.REQUEST_GET_PARTNER_DATASETS:
            await _getPartnerDatasets(state, action, dispatch);
            break;

        case Actions.REQUEST_GET_PARTNER_SHARED_ASSETS:
            await _getPartnerSharedAssets(state, action, dispatch);
            break;

        case Actions.REQUEST_PARTNER_SHARE_ASSET:
            await _requestShareAsset(state, action, dispatch);
            break;

        case Actions.REQUEST_ACCEPT_ASSET_REQUEST:
            await _acceptAssetRequest(state, action, dispatch);
            break;

        case Actions.REQUEST_REFUSE_ASSET_REQUEST:
            await _refuseAssetRequest(state, action, dispatch);
            break;

        case Actions.REQUEST_REMOVE_PARTNERS:
            await _removeCollaboration(state, action, dispatch);
            break;

    }
}

const _getPartners = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/partners`,
            params: {
                page: _.get(state, 'partners.index.current_page', 0) + 1
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_GET_PARTNERS_SUCCESS, payload: appResponse.data });
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _getPartnerDetail = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/partners/${action.payload.collaborationId}`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_GET_PARTNER_DETAIL_SUCCESS, payload: appResponse.data });
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _getPartnerApplications = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/partners/${action.payload.collaborationId}/app`,
            params: {
                page: _.get(state, 'partners.detail.applications.current_page', 0) + 1
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_GET_PARTNER_APPLICATIONS_SUCCESS, payload: appResponse.data });
        } else {
            setTimeout(function () {
                dispatch({ //this dispatch my need a delay of 100-200 ms in the future
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _getPartnerDatasets = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/partners/${action.payload.collaborationId}/dataset`,
            params: {
                page: _.get(state, 'partners.detail.datasets.current_page', 0) + 1
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_GET_PARTNER_DATASETS_SUCCESS, payload: appResponse.data });
        } else {
            setTimeout(function () {
                dispatch({  //this dispatch my need a delay of 100-200 ms in the future
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _getPartnerSharedAssets = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/partners/${action.payload.collaborationId}/shared`,
            params: {
                page: _.get(state, 'partners.detail.shared.current_page', 0) + 1
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_GET_PARTNER_SHARED_ASSETS_SUCCESS, payload: appResponse.data });
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _requestShareAsset = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/partners/${action.payload.collaborationId}/shared`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
            data: {asset_id: action.payload.assetId}
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_PARTNER_SHARE_ASSET_SUCCESS, payload: appResponse.data });
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _acceptAssetRequest = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/partners/${action.payload.collaborationId}/shared/${action.payload.assetId}`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            setTimeout(function () {
                dispatch({ type: Actions.RESPONSE_ACCEPT_ASSET_REQUEST_SUCCESS, payload: appResponse.data });
            }, 500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _refuseAssetRequest = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/partners/${action.payload.collaborationId}/shared/${action.payload.assetId}`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            setTimeout(function () {
                dispatch({ type: Actions.RESPONSE_REFUSE_ASSET_REQUEST_SUCCESS, payload: appResponse.data });
            }, 500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _removeCollaboration = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/partners/${action.payload.collaborationId}`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            setTimeout(function () {
                dispatch({ type: Actions.RESPONSE_REMOVE_PARTNERS_SUCCESS, payload: appResponse.data });
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'success', message: "Remove collaboration successful." }
                });
            }, 500);

            setTimeout(function () {
                window.location.replace('/partners');
            }, 1500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};
