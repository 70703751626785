export default {
    REQUEST_GET_COLLABORATIONS: "REQUEST_GET_COLLABORATIONS",
    RESPONSE_GET_COLLABORATIONS_SUCCESS: "RESPONSE_GET_COLLABORATIONS_SUCCESS",
    RESPONSE_GET_COLLABORATIONS_FAILED: "RESPONSE_GET_COLLABORATIONS_FAILED",

    REQUEST_GET_COLLABORATION_DETAIL: "REQUEST_GET_COLLABORATION_DETAIL",
    RESPONSE_GET_COLLABORATION_DETAIL_SUCCESS: "RESPONSE_GET_COLLABORATION_DETAIL_SUCCESS",
    RESPONSE_GET_COLLABORATION_DETAIL_FAILED: "RESPONSE_GET_COLLABORATION_DETAIL_FAILED",

    REQUEST_CREATE_NEW_COLLABORATION: "REQUEST_CREATE_NEW_COLLABORATION",
    RESPONSE_CREATE_NEW_COLLABORATION_SUCCESS: "RESPONSE_CREATE_NEW_COLLABORATION_SUCCESS",
    RESPONSE_CREATE_NEW_COLLABORATION_FAILED: "RESPONSE_CREATE_NEW_COLLABORATION_FAILED",

    REQUEST_ACCEPT_COLLABORATION: "REQUEST_ACCEPT_COLLABORATION",
    RESPONSE_ACCEPT_COLLABORATION_SUCCESS: "RESPONSE_ACCEPT_COLLABORATION_SUCCESS",
    RESPONSE_ACCEPT_COLLABORATION_FAILED: "RESPONSE_ACCEPT_COLLABORATION_FAILED",

    REQUEST_REFUSE_COLLABORATION: "REQUEST_REFUSE_COLLABORATION",
    RESPONSE_REFUSE_COLLABORATION_SUCCESS: "RESPONSE_REFUSE_COLLABORATION_SUCCESS",
    RESPONSE_REFUSE_COLLABORATION_FAILED: "RESPONSE_REFUSE_COLLABORATION_FAILED",
}
