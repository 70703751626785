
import Actions from '../actions/logs';

export default (state, action) => {

    switch(action.type) {
        case Actions.RESPONSE_GET_LOGS_SUCCESS:
            return {
                ...state,
                index: {
                    items: state?.items ? [...state.items, ...action.payload.items] : action.payload.items,
                    total: action.payload.total,
                    page_size: action.payload.page_size,
                    current_page: action.payload.current_page,
                }
            };

        default:
            return state;
    }
};
