import _ from "lodash";

import Actions from '../actions/collaborations';

export default (state, action) => {

    switch(action.type) {
        case Actions.RESPONSE_GET_COLLABORATIONS_SUCCESS:
            return {
                ...state,
                index: {
                    items: state.index?.items ? [...state.index.items, ...action.payload.items] : action.payload.items,
                    total: action.payload.total,
                    page_size: action.payload.page_size,
                    current_page: action.payload.current_page
                }
            };

        case Actions.RESPONSE_GET_COLLABORATION_DETAIL_SUCCESS:
            return {
                ...state,
                detail: action.payload
            };

        case Actions.RESPONSE_CREATE_NEW_COLLABORATION_SUCCESS:
            // ThanhNT: do not need to update state, it may cause an error due to state.collaborations.index === null
            // if creation success --> browser will redirect + fetch new data again
            return state;

        case Actions.RESPONSE_ACCEPT_COLLABORATION_SUCCESS:
        case Actions.RESPONSE_REFUSE_COLLABORATION_SUCCESS:
            let newState = _.cloneDeep(state);
            _.forEach(newState.index.items, function(value, key) {
                if(value._id === action.payload._id) {
                    newState.index.items[key] = action.payload;
                }
            });
            return newState;

        default:
            return state;
    }
};
