import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import _ from "lodash";

import AppLayout from "../../components/layouts/app";
import {context} from "../../context";
import ResultActions from '../../context/actions/results';
import {Link} from "react-router-dom";
import BarChartIcon from "@mui/icons-material/BarChart";

export default () => {
    const {state, dispatch} = useContext(context);

    useEffect(async () => {
        if(state.results === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: ResultActions.REQUEST_GET_RESULTS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.results]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let resultItems = _.get(state, 'results.items', []);
    let totalRecord = resultItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);
    const _renderResults = () => {

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listResults = resultItems.filter(function (result, index) {
            return index >= from && index <= to;
        }).map(function (result) {
            let collaborators = result?.collaborators ? JSON.parse(result.collaborators): [];
            let collaboratorNames = collaborators.map(collaborator => (<li>{collaborator.company.name}</li>));

            return (
                <tr key={result._id}>
                    <td>{result.enclave.project_id}</td>
                    <td>{result.uri}</td>
                    <td>{result.decryption_key}</td>
                    <td><ul>{collaboratorNames}</ul></td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Enclave ID</th>
                    <th>Storage URI</th>
                    <th>Decryption Key</th>
                    <th>Collaborators</th>
                </tr>
                </thead>
                <tbody>
                { listResults }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.results.items.length < state.results.total)) {
                dispatch({ type: ResultActions.REQUEST_GET_RESULTS });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    return (
        <AppLayout>
            <div id="results-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/results" className="disabled" >
                                <BarChartIcon className="icon"/>
                                <span>Results</span>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="card">
                    { _renderResults() }

                    { _renderPagination() }
                </div>
            </div>
        </AppLayout>
    );
};
