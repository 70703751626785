import Actions from '../actions/companies';

export default (state, action) => {

    switch(action.type) {
        case Actions.RESPONSE_SEARCH_COMPANIES_SUCCESS:
            return {
                search: {
                    keyword: action.payload.keyword,
                    results: action.payload.results
                }
            }

        default:
            return state;
    }
};
