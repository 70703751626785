export default {
    REQUEST_GET_DATASETS: "REQUEST_GET_DATASETS",
    RESPONSE_GET_DATASETS_SUCCESS: "RESPONSE_GET_DATASETS_SUCCESS",
    RESPONSE_GET_DATASETS_FAILED: "RESPONSE_GET_DATASETS_FAILED",

    REQUEST_GET_DATASET_DETAIL: "REQUEST_GET_DATASET_DETAIL",
    RESPONSE_GET_DATASET_DETAIL_SUCCESS: "RESPONSE_GET_DATASET_DETAIL_SUCCESS",
    RESPONSE_GET_DATASET_DETAIL_FAILED: "RESPONSE_GET_DATASET_DETAIL_FAILED",

    REQUEST_CREATE_NEW_DATASET: "REQUEST_CREATE_NEW_DATASET",
    RESPONSE_CREATE_NEW_DATASET_SUCCESS: "RESPONSE_CREATE_NEW_DATASET_SUCCESS",
    RESPONSE_CREATE_NEW_DATASET_FAILED: "RESPONSE_CREATE_NEW_DATASET_FAILED",

    REQUEST_DELETE_DATASET: "REQUEST_DELETE_DATASET",
    RESPONSE_DELETE_DATASET_SUCCESS: "RESPONSE_DELETE_DATASET_SUCCESS",
    RESPONSE_DELETE_DATASET_FAILED: "RESPONSE_DELETE_DATASET_FAILED",
}
