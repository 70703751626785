import _ from "lodash";

import Actions from '../actions/partners';

export default (state, action) => {

    switch(action.type) {
        case Actions.RESPONSE_GET_PARTNERS_SUCCESS:
            return {
                ...state,
                index: {
                    items: state?.index?.items ? [...state.index.items, ...action.payload.items] : action.payload.items,
                    total: action.payload.total,
                    page_size: action.payload.page_size,
                    current_page: action.payload.current_page,
                }
            };

        case Actions.RESPONSE_GET_PARTNER_DETAIL_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    partner: action.payload
                }
            };

        case Actions.RESPONSE_GET_PARTNER_DETAIL_FAILED:
            return {
                ...state,
                detail: {
                    partner: null,
                    shared: null,
                    applications: null,
                    datasets: null,
                }
            };

        case Actions.RESPONSE_GET_PARTNER_APPLICATIONS_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    applications: {
                        items: state?.detail?.applications?.items ? [...state.detail.applications.items, ...action.payload.items] : action.payload.items,
                        total: action.payload.total,
                        page_size: action.payload.page_size,
                        current_page: action.payload.current_page,
                    }
                }
            };

        case Actions.RESPONSE_GET_PARTNER_DATASETS_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    datasets: {
                        items: state?.detail?.datasets?.items ? [...state.detail.datasets.items, ...action.payload.items] : action.payload.items,
                        total: action.payload.total,
                        page_size: action.payload.page_size,
                        current_page: action.payload.current_page,
                    }
                }
            };

        case Actions.RESPONSE_GET_PARTNER_SHARED_ASSETS_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    shared: {
                        items: state?.detail?.shared?.items ? [...state.detail.shared.items, ...action.payload.items] : action.payload.items,
                        total: action.payload.total,
                        page_size: action.payload.page_size,
                        current_page: action.payload.current_page,
                    }
                }
            };

        case Actions.RESPONSE_PARTNER_SHARE_ASSET_SUCCESS:
            let newState1 = _.cloneDeep(state);
            _.forEach(newState1.detail[`${action.payload.type}s`].items, function(value, key) {
                if(value._id === action.payload.asset_id) {
                    newState1.detail[`${action.payload.type}s`].items[key] = {...value, request_status: action.payload.status};
                }
            });
            return newState1;

        case Actions.RESPONSE_ACCEPT_ASSET_REQUEST_SUCCESS:
        case Actions.RESPONSE_REFUSE_ASSET_REQUEST_SUCCESS:
            let newState2 = _.cloneDeep(state);
            _.forEach(newState2.detail.shared.items, function(value, key) {
                if(value.asset_id === action.payload.asset_id) {
                    newState2.detail.shared.items[key] = {...value, status: action.payload.status};
                }
            });
            return newState2;

        default:
            return state;
    }
};
