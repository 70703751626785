import axios from "axios";

import Actions from '../actions/auth';

export default async (state, action, dispatch) => {
    switch(action.type) {
        case Actions.REQUEST_SIGNIN:
            await _signin(state, action, dispatch);
            break;

        case Actions.REQUEST_SIGNUP:
            await _signup(state, action, dispatch);
            break;
    }
}

const _signin = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/signin`,
            headers: {'Content-Type': 'application/json'},
            data: action.payload
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            const accessToken = appResponse.data.token;
            window.localStorage.clear();
            window.localStorage.setItem('access_token', accessToken);
            setTimeout(function () {
                window.location.replace('/');
            }, 500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
}

const _signup = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/signup`,
            headers: {'Content-Type': 'application/json'},
            data: action.payload
        });

        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            const accessToken = appResponse.data.token;
            window.localStorage.setItem('access_token', accessToken);
            setTimeout(function () {
                window.location.replace('/');
            }, 500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
}

