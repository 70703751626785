import './common.scss';
import React, {useEffect, useState, useContext} from 'react';

import {context} from "../../context";
import CircularProgress from "@mui/material/CircularProgress";
import {Alert, AlertTitle, Slide, Snackbar} from "@mui/material";

export default ({children}) => {

    const {state, dispatch} = useContext(context);

    const _hideGlobalMessage = (e, reason) => {
        if (reason === 'clickaway') {
            return false;
        }

        dispatch({ type: "HIDE_GLOBAL_MESSAGE" });
        return true;
    }

    return (
        <div id="guest-page">

            { children }

            <div id="loading" className={state.global_loading ? "show" : ""}>
                <CircularProgress/>
            </div>

            <Snackbar
                id="global-message"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.global_message.open}
                autoHideDuration={5000}
                onClose={_hideGlobalMessage}
                TransitionComponent={Slide}
            >
                <Alert onClose={_hideGlobalMessage} severity={state.global_message.type}>
                    <AlertTitle style={{textTransform: "capitalize"}}>{state.global_message.type}</AlertTitle>
                    {state.global_message.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
