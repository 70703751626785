import React from "react";
import ReactDOM from "react-dom";
import {StateProvider} from './context';
import Application from "./application";

ReactDOM.render(
    <React.StrictMode>
        <StateProvider>
            <Application/>
        </StateProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
