export default {
    REQUEST_GET_PARTNERS: "REQUEST_GET_PARTNERS",
    RESPONSE_GET_PARTNERS_SUCCESS: "RESPONSE_GET_PARTNERS_SUCCESS",
    RESPONSE_GET_PARTNERS_FAILED: "RESPONSE_GET_PARTNERS_FAILED",

    REQUEST_GET_PARTNER_DETAIL: "REQUEST_GET_PARTNER_DETAIL",
    RESPONSE_GET_PARTNER_DETAIL_SUCCESS: "RESPONSE_GET_PARTNER_DETAIL_SUCCESS",
    RESPONSE_GET_PARTNER_DETAIL_FAILED: "RESPONSE_GET_PARTNER_DETAIL_FAILED",

    REQUEST_GET_PARTNER_SHARED_ASSETS: "REQUEST_GET_PARTNER_SHARED_ASSETS",
    RESPONSE_GET_PARTNER_SHARED_ASSETS_SUCCESS: "RESPONSE_GET_PARTNER_SHARED_ASSETS_SUCCESS",
    RESPONSE_GET_PARTNER_SHARED_ASSETS_FAILED: "RESPONSE_GET_PARTNER_SHARED_ASSETS_FAILED",

    REQUEST_GET_PARTNER_APPLICATIONS: "REQUEST_GET_PARTNER_APPLICATIONS",
    RESPONSE_GET_PARTNER_APPLICATIONS_SUCCESS: "RESPONSE_GET_PARTNER_APPLICATIONS_SUCCESS",
    RESPONSE_GET_PARTNER_APPLICATIONS_FAILED: "RESPONSE_GET_PARTNER_APPLICATIONS_FAILED",

    REQUEST_GET_PARTNER_DATASETS: "REQUEST_GET_PARTNER_DATASETS",
    RESPONSE_GET_PARTNER_DATASETS_SUCCESS: "RESPONSE_GET_PARTNER_DATASETS_SUCCESS",
    RESPONSE_GET_PARTNER_DATASETS_FAILED: "RESPONSE_GET_PARTNER_DATASETS_FAILED",

    REQUEST_PARTNER_SHARE_ASSET: "REQUEST_PARTNER_SHARE_ASSET",
    RESPONSE_PARTNER_SHARE_ASSET_SUCCESS: "RESPONSE_PARTNER_SHARE_ASSET_SUCCESS",
    RESPONSE_PARTNER_SHARE_ASSET_FAILED: "RESPONSE_PARTNER_SHARE_ASSET_FAILED",

    REQUEST_ACCEPT_ASSET_REQUEST: "REQUEST_ACCEPT_ASSET_REQUEST",
    RESPONSE_ACCEPT_ASSET_REQUEST_SUCCESS: "RESPONSE_ACCEPT_ASSET_REQUEST_SUCCESS",
    RESPONSE_ACCEPT_ASSET_REQUEST_FAILED: "RESPONSE_ACCEPT_ASSET_REQUEST_FAILED",

    REQUEST_REFUSE_ASSET_REQUEST: "REQUEST_REFUSE_ASSET_REQUEST",
    RESPONSE_REFUSE_ASSET_REQUEST_SUCCESS: "RESPONSE_REFUSE_ASSET_REQUEST_SUCCESS",
    RESPONSE_REFUSE_ASSET_REQUEST_FAILED: "RESPONSE_REFUSE_ASSET_REQUEST_FAILED",

    REQUEST_REMOVE_PARTNERS: "REQUEST_REMOVE_PARTNERS",
    RESPONSE_REMOVE_PARTNERS_SUCCESS: "RESPONSE_REMOVE_PARTNERS_SUCCESS",
    RESPONSE_REMOVE_PARTNERS_FAILED: "RESPONSE_REMOVE_PARTNERS_FAILED",
}
