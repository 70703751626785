import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import AppLayout from "../../components/layouts/app";

import {context} from "../../context";
import DatasetActions from '../../context/actions/datasets';
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Link} from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default () => {
    const {state, dispatch} = useContext(context);

    useEffect(async () => {
        if(state.datasets.index === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: DatasetActions.REQUEST_GET_DATASETS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
            setNewDataset({
                name: "", description: "",
                status: "private",
                public_endpoint: "", owner: "",
                s3_endpoint: "", s3_file_uri: "", s3_policy_name: "", s3_result_uri: ""
            });
            setOpenCreatingModal(false);
            setDeletingDataset({});
            setOpenDeletingModal(false);
        }
    }, [state.datasets.index]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let datasetItems = _.get(state, 'datasets.index.items', []);
    let totalRecord = datasetItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);
    const _renderDatasets = () => {

        const _deleteDataset = (dataset) => {
            setOpenDeletingModal(true);
            setDeletingDataset(dataset);
        }

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listDatasets = datasetItems.filter(function (dataset, index) {
            return index >= from && index <= to;
        }).map(function (dataset) {
            return (
                <tr key={dataset._id}>
                    <td>{dataset.name}</td>
                    <td>{dataset.owner}</td>
                    <td>{dataset.status}</td>
                    <td>{dataset.public_endpoint || dataset.s3_endpoint }</td>
                    <td>{dataset.s3_policy_name}</td>
                    <td>
                        <Link to={`/datasets/${dataset._id}`} className="link"><VisibilityIcon sx={{ color: 'rgb(25, 118, 210)' }} /></Link>
                        <span className="delete" onClick={() => _deleteDataset(dataset)}>
                            <DeleteIcon  sx={{ color: 'rgb(239, 83, 80)' }} />
                        </span>
                    </td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Status</th>
                    <th>Endpoint</th>
                    <th>Policy Name</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { listDatasets }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.datasets.index.items.length < state.datasets.index.total)) {
                dispatch({ type: DatasetActions.REQUEST_GET_DATASETS });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    const [openCreatingModal, setOpenCreatingModal] = useState(false);
    const [newDataset, setNewDataset] = useState(
        {
            name: "", description: "",
            status: "private",
            public_endpoint: "", owner: "",
            s3_endpoint: "", s3_file_uri: "", s3_policy_name: "", s3_result_uri: ""
        }
    );
    const _renderCreatingModal = () => {
        if(!openCreatingModal) { return (<div />); }

        const _renderResourceEndpoint = () => {
            if(newDataset.status === 'private') {
                return (
                    <div className="endpoint">
                        <TextField
                            id="input-s3_endpoint"
                            required
                            label="S3 Endpoint"
                            name="s3_endpoint"
                            size="small"
                            fullWidth={true}
                            placeholder="https://minio.confidentialcloud.io"
                            value={newDataset.s3_endpoint}
                            onChange={(e) => {setNewDataset({...newDataset, s3_endpoint: e.target.value})}}
                            onFocus={(e) => {
                                if(_.isEmpty(e.target.value)) {
                                    setNewDataset({...newDataset, s3_endpoint: "https://"});
                                }
                            }}
                            onBlur={(e) => {
                                if(e.target.value === "https://") {
                                    setNewDataset({...newDataset, s3_endpoint: ""});
                                }
                            }}
                        />
                        <TextField
                            id="input-s3_file_uri"
                            required
                            label="S3 File URI"
                            name="s3_file_uri"
                            size="small"
                            fullWidth={true}
                            placeholder="s3://my-bucket/my-object"
                            value={newDataset.s3_file_uri}
                            onChange={(e) => {setNewDataset({...newDataset, s3_file_uri: e.target.value, s3_result_uri: `${e.target.value}-results`})}}
                            onFocus={(e) => {
                                if(_.isEmpty(e.target.value)) {
                                    setNewDataset({...newDataset, s3_file_uri: "s3://"});
                                }
                            }}
                            onBlur={(e) => {
                                if(e.target.value === "s3://") {
                                    setNewDataset({...newDataset, s3_file_uri: ""});
                                }
                            }}
                        />
                        <TextField
                            id="input-s3_policy_name"
                            required
                            label="S3 Policy Name"
                            name="s3_policy_name"
                            size="small"
                            fullWidth={true}
                            value={newDataset.s3_policy_name}
                            onChange={(e) => {setNewDataset({...newDataset, s3_policy_name: e.target.value})}}
                        />
                        <TextField
                            id="input-s3_result_uri"
                            required
                            label="S3 Result URI"
                            name="s3_result_uri"
                            size="small"
                            fullWidth={true}
                            value={newDataset.s3_result_uri}
                            onChange={(e) => {setNewDataset({...newDataset, s3_result_uri: e.target.value})}}
                            onFocus={(e) => {
                                if(_.isEmpty(e.target.value)) {
                                    setNewDataset({...newDataset, s3_result_uri: "s3://"});
                                }
                            }}
                            onBlur={(e) => {
                                if(e.target.value === "s3://") {
                                    setNewDataset({...newDataset, s3_result_uri: ""});
                                }
                            }}
                        />
                    </div>
                );
            }

            return (
                <div className="endpoint">
                    <TextField
                        id="input-public_endpoint"
                        required
                        label="Public Endpoint"
                        name="public_endpoint"
                        size="small"
                        fullWidth={true}
                        value={newDataset.public_endpoint}
                        onChange={(e) => {setNewDataset({...newDataset, public_endpoint: e.target.value})}}
                        onFocus={(e) => {
                            if(_.isEmpty(e.target.value)) {
                                setNewDataset({...newDataset, public_endpoint: "https://"});
                            }
                        }}
                        onBlur={(e) => {
                            if(e.target.value === "https://") {
                                setNewDataset({...newDataset, public_endpoint: ""});
                            }
                        }}
                    />
                    <TextField
                        id="input-owner"
                        required
                        label="Owner"
                        name="owner"
                        size="small"
                        fullWidth={true}
                        value={newDataset.owner}
                        onChange={(e) => {setNewDataset({...newDataset, owner: e.target.value})}}
                    />
                </div>
            );
        }

        const _submit = async (e) => {
            e.preventDefault();
            let data = _.cloneDeep(newDataset);
            if(_.isEmpty(data.description)) {
                delete data.description;
            }
            if(data.status === 'private') {
                delete data.public_endpoint;
                delete data.owner;
            } else {
                delete data.s3_endpoint;
                delete data.s3_file_uri;
                delete data.s3_policy_name;
                delete data.s3_result_uri;
            }
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: DatasetActions.REQUEST_CREATE_NEW_DATASET, payload: {...data} });
        }

        return (
            <Modal
                id="modal-datasets-create-new"
                open={openCreatingModal}
                onClose={() => setOpenCreatingModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openCreatingModal}>
                    <Box className="modal-content">
                        <form onSubmit={_submit}>
                            <div className="header">
                                <h2 className="title">Add New Datasets</h2>
                            </div>
                            <div className="form">
                                <TextField
                                    id="input-name"
                                    required
                                    label="Name"
                                    size="small"
                                    fullWidth={true}
                                    value={newDataset.name}
                                    onChange={(e) => {setNewDataset({...newDataset, name: e.target.value})}}
                                />
                                <TextField
                                    id="input-description"
                                    label="Description"
                                    size="small"
                                    fullWidth={true}
                                    multiline
                                    rows={2}
                                    value={newDataset.description}
                                    onChange={(e) => {setNewDataset({...newDataset, description: e.target.value})}}
                                />
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        id="input-status"
                                        required
                                        label="Status"
                                        value={newDataset.status}
                                        onChange={(e) => {setNewDataset({...newDataset, status: e.target.value})}}
                                    >
                                        <MenuItem value="public">Public</MenuItem>
                                        <MenuItem value="private">Private</MenuItem>
                                    </Select>
                                </FormControl>

                                { _renderResourceEndpoint() }
                            </div>
                            <div className="footer">
                                <Button variant="outlined" onClick={() => setOpenCreatingModal(false)}>Cancel</Button>
                                <Button variant="contained" type="submit">Submit</Button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        );
    }

    const [openDeletingModal, setOpenDeletingModal] = useState(false);
    const [deletingDataset, setDeletingDataset] = useState({});
    const _renderDeletingModal = () => {
        if(!openDeletingModal) { return (<div />); }

        const _renderAppDetail = () => {
            if((deletingDataset.status === 'private')) {
                return (
                    <table>
                        <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{deletingDataset.name}</td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>{deletingDataset.description}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{deletingDataset.status}</td>
                        </tr>
                        <tr>
                            <td>Owner</td>
                            <td>{deletingDataset.owner}</td>
                        </tr>
                        <tr>
                            <td>S3 Endpoint</td>
                            <td>{deletingDataset.s3_endpoint}</td>
                        </tr>
                        <tr>
                            <td>S3 File URI</td>
                            <td>{deletingDataset.s3_file_uri}</td>
                        </tr>
                        <tr>
                            <td>S3 Policy Name</td>
                            <td>{deletingDataset.s3_policy_name}</td>
                        </tr>
                        <tr>
                            <td>S3 Result URI</td>
                            <td>{deletingDataset.s3_result_uri}</td>
                        </tr>
                        </tbody>
                    </table>
                );
            }

            return (
                <table>
                    <tbody>
                    <tr>
                        <td>Name</td>
                        <td>{deletingDataset.name}</td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>{deletingDataset.description}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{deletingDataset.status}</td>
                    </tr>
                    <tr>
                        <td>Owner</td>
                        <td>{deletingDataset.owner}</td>
                    </tr>
                    <tr>
                        <td>Public Endpoint</td>
                        <td>{deletingDataset.public_endpoint}</td>
                    </tr>
                    </tbody>
                </table>
            );
        }

        const _submit = async (e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: DatasetActions.REQUEST_DELETE_DATASET, payload: {id: deletingDataset._id} });
        }

        return (
            <Modal
                id="modal-datasets-delete"
                open={openDeletingModal}
                onClose={() => setOpenDeletingModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openDeletingModal}>
                    <Box className="modal-content">
                        <form onSubmit={_submit}>
                            <div className="header">
                                <h2 className="title">Delete this Dataset?</h2>
                            </div>
                            <div className="content">
                                { _renderAppDetail() }
                            </div>
                            <div className="footer">
                                <Button variant="outlined" onClick={() => setOpenDeletingModal(false)}>Cancel</Button>
                                <Button variant="contained" type="submit" color="error">Delete it!</Button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        );
    }

    return (
        <AppLayout>
            <div id="datasets-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/datasets" className="disabled" >
                                <CategoryIcon className="icon"/>
                                <span>Datasets</span>
                            </Link>
                        </li>
                    </ul>

                    <Button id="add-new-dataset-btn" variant="outlined" onClick={() => setOpenCreatingModal(true)}>
                        Add New
                    </Button>
                </div>

                <div className="card">
                    { _renderDatasets() }
                    { _renderPagination() }

                    { _renderCreatingModal() }
                    { _renderDeletingModal() }
                </div>
            </div>
        </AppLayout>
    );
}
