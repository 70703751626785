import {BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import React, {useEffect, useState, useContext} from "react";

import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split} from "@apollo/client";
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import {getMainDefinition} from "@apollo/client/utilities";

import CircularProgress from "@mui/material/CircularProgress";
import _ from 'lodash';

import {context} from "./context";
import ProfileActions from './context/actions/profile';
import DashboardActions from './context/actions/dashboard';
import Signin from "./pages/auth/signin";
import Signup from "./pages/auth/signup";
import AuthChallengeX509Certificate from "./pages/auth/challenges/x509_certificate";
import AuthChallengeCompanyProfile from "./pages/auth/challenges/update_company_profile";
import AuthChallengeUnderReview from "./pages/auth/challenges/under_review";
import Home from "./pages/home/Home";
import Applications from "./pages/applications/index";
import ApplicationDetail from "./pages/applications/detail";
import Datasets from "./pages/datasets/index";
import DatasetDetail from "./pages/datasets/detail";
import Enclaves from "./pages/enclaves/index";
import EnclaveCreating from "./pages/enclaves/create";
import Attestations from "./pages/attestations/index";
import AttestationDetail from "./pages/attestations/detail";
import Collaborations from "./pages/collaborations/index";
import Partners from "./pages/partners/index";
import PartnerDetailIndex from "./pages/partners/detail/index";
import PartnerDetailApp from "./pages/partners/detail/app";
import PartnerDetailDataset from "./pages/partners/detail/dataset";
import Results from "./pages/results/index";
import Notifications from "./pages/notifications/index";
import Profile from "./pages/profile/index";
import Logs from "./pages/logs/index";
import LogsDetailRequest from "./pages/logs/detail/request";
import LogsDetailExecution from "./pages/logs/detail/execution";
import Billing from "./pages/billing/index";

export default () => {
    const {state, dispatch} = useContext(context);

    useEffect(
        async () => {
            if (state.authenticated_user === null) {
                try {
                    dispatch({type: ProfileActions.REQUEST_GET_AUTHENTICATED_USER});
                } catch (e) {
                    console.log(e.message);
                    if(window.location.pathname !== '/signin') {
                        window.location.replace('/signin');
                    }
                }
            } else if(_.isObject(state.authenticated_user) && _.isEmpty(state.authenticated_user)) {
                if((window.location.pathname !== '/signup') && (window.location.pathname !== '/signin')) {
                    window.location.replace('/signin');
                }
            } else {
                if(_.isEmpty(window.localStorage.getItem('dashboard_data'))) {
                    let dashboardData = JSON.parse(state.authenticated_user.dashboard);
                    if(_.isEmpty(dashboardData)) {
                        dashboardData = {
                            applications: 0,
                            datasets: 0,
                            enclaves: 0,
                            results: 0
                        };
                    }
                    window.localStorage.setItem('dashboard_data', JSON.stringify(dashboardData));
                    dispatch({type: DashboardActions.RESPONSE_GET_DASHBOARD_DATA_SUCCESS});
                }

                if(_.indexOf(state.authenticated_user.auth_challenges, 'upload_x509_certificate') !== -1) {
                    if(window.location.pathname !== '/x509-certificate') {
                        window.location.replace('/x509-certificate');
                    }
                } else if(_.indexOf(state.authenticated_user.auth_challenges, 'update_company_profile') !== -1) {
                    if(window.location.pathname !== '/company-profile') {
                        window.location.replace('/company-profile');
                    }
                } else if(_.indexOf(state.authenticated_user.auth_challenges, 'require_admin_confirmation') !== -1) {
                    if(window.location.pathname !== '/under-review') {
                        window.location.replace('/under-review');
                    }
                } else {
                    if((window.location.pathname === '/x509-certificate') || (window.location.pathname === '/company-profile') || (window.location.pathname === '/under-review')) {
                        window.location.replace('/');
                    }
                }
            }
        },
        [state.authenticated_user]
    );

    const _renderApplication = () => {
        if (state.authenticated_user === null) {
            return (
                <div id="loading" className="show">
                    <CircularProgress/>
                </div>
            );
        }

        if(_.isObject(state.authenticated_user) && _.isEmpty(state.authenticated_user)) {
            if(window.location.pathname === '/signup') {
                return (<Signup />);
            } else if(window.location.pathname !== '/signin') {
                return window.location.replace('/signin');
            } else {
                return (<Signin />);
            }
        }

        const httpLink = new HttpLink({
            uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
            headers: {
                authorization: `Bearer ${state.authenticated_user.access_token}`
            }
        });
        const wsLink = new WebSocketLink(
            new SubscriptionClient(
                'ws://localhost:3001/subscriptions',
                {
                    options: {
                        connectionParams: {
                            authToken: `Bearer ${state.authenticated_user.access_token}`
                        },
                    }
                }
            )
        );
        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink,
        );
        const client = new ApolloClient({
            link: splitLink,
            cache: new InMemoryCache()
        });

        return (
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/applications" element={<Applications/>}/>
                        <Route path="/applications/:application_id" element={<ApplicationDetail/>}/>
                        <Route path="/datasets" element={<Datasets/>}/>
                        <Route path="/datasets/:dataset_id" element={<DatasetDetail/>}/>
                        <Route path="/enclaves" element={<Enclaves/>}/>
                        <Route path="/enclaves/create" element={<EnclaveCreating/>}/>
                        <Route path="/attestations" element={<Attestations/>}/>
                        <Route path="/attestations/:attestation_id" element={<AttestationDetail/>}/>
                        <Route path="/collaborations" element={<Collaborations/>}/>
                        <Route path="/partners/:collaboration_id/dataset" element={<PartnerDetailDataset/>}/>
                        <Route path="/partners/:collaboration_id/app" element={<PartnerDetailApp/>}/>
                        <Route path="/partners/:collaboration_id" element={<PartnerDetailIndex/>}/>
                        <Route path="/partners" element={<Partners/>}/>
                        <Route path="/results" element={<Results/>}/>
                        <Route path="/x509-certificate" element={<AuthChallengeX509Certificate/>}/>
                        <Route path="/company-profile" element={<AuthChallengeCompanyProfile/>}/>
                        <Route path="/under-review" element={<AuthChallengeUnderReview/>}/>
                        <Route path="/notifications" element={<Notifications/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/logs" element={<Logs/>}/>
                        <Route path="/logs/:log_id" element={<LogsDetailRequest/>}/>
                        <Route path="/logs/:log_id/asset/:log_asset_id" element={<LogsDetailExecution/>}/>
                        <Route path="/billing" element={<Billing/>}/>
                        <Route path="*" element={<Navigate to="/" replace />}/>
                    </Routes>
                </BrowserRouter>
            </ApolloProvider>
        );
    }

    return _renderApplication();
}
