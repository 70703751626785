import axios from "axios";
import _ from "lodash";

import Actions from '../actions/logs';

export default async (state, action, dispatch) => {
    switch(action.type) {
        case Actions.REQUEST_GET_LOGS:
            await _getLogs(state, action, dispatch);
            break;

        case Actions.REQUEST_GET_LOG_DETAIL:
            await _getLogDetail(state, action, dispatch);
            break;

        case Actions.REQUEST_CREATE_NEW_LOG:
            await _getCreateLog(state, action, dispatch);
            break;
        
        case Actions.REQUEST_DELETE_LOGS:
            await _getDeleteLog(state, action, dispatch);
            break;
    }
}

const _getLogs = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: "http://localhost:3001/v1/logs",
            params: {
                page: _.get(state, 'logs.current_page', 0) + 1
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        dispatch({ type: Actions.RESPONSE_GET_LOGS_SUCCESS, payload: appResponse.data });
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
}


const _getLogDetail = async (state, action, dispatch) => {

}

const _getCreateLog = async (state, action, dispatch) => {

}

const _getDeleteLog = async (state, action, dispatch) => {

}