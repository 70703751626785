import "./widget.scss";
import BarChartIcon from '@mui/icons-material/BarChart';
import CategoryIcon from '@mui/icons-material/Category';
import CodeIcon from '@mui/icons-material/Code';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';

// const Widget = ({type}) => {
const Widget = ({ type, amount }) => {
    let data;

    switch (type) {
        case "dataset":
            data = {
                title: "DATASETS",
                isMoney: false,
                link: (
                    <a href="/datasets">View all Datasets</a>
                ),
                icon: (
                    <CategoryIcon
                        className="icon"
                        style={{
                            color: "#0097a7",
                            backgroundColor: "#EAF4F6",
                        }}
                    />
                ),
            };
            break;
        case "application":
            data = {
                title: "APPLICATIONS",
                isMoney: false,
                link: (
                    <a href="/applications">View all Applications</a>
                ),
                icon: (
                    <CodeIcon
                        className="icon"
                        style={{
                            color: "#0097a7",
                            backgroundColor: "#EAF4F6",
                        }}
                    />
                ),
            };
            break;
        case "enclave":
            data = {
                title: "ENCLAVES",
                isMoney: false,
                link: (
                    <a href="/enclaves">View all Enclaves</a>
                ),
                icon: (
                    <FeaturedVideoIcon
                        className="icon"
                        style={{
                            color: "#0097a7",
                            backgroundColor: "#EAF4F6",
                        }}
                    />
                ),
            };
            break;
        case "result":
            data = {
                title: "RESULTS",
                isMoney: false,
                link: (
                    <a href="/results">View all Results</a>
                ),
                icon: (
                    <BarChartIcon
                        className="icon"
                        style={{
                            color: "#0097a7",
                            backgroundColor: "#EAF4F6",
                        }}
                    />
                ),
            };
            break;
        default:
            break;
    }

    return (
        <div className="widget">
            <div className="left">
                <span className="title">{data.title}</span>
                <span className="counter">
                  {data.isMoney && "$"} {amount}
                </span>
                <span className="link">{data.link}</span>
            </div>
            <div className="right">
                {data.icon}
            </div>
        </div>
    );
};

export default Widget;
