import './detail.scss';
import React, {useEffect, useState, useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";

import AppLayout from "../../components/layouts/app";
import {context} from "../../context";
import DatasetActions from '../../context/actions/datasets';
import _ from "lodash";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";

export default () => {

    const {state, dispatch} = useContext(context);
    const {dataset_id} = useParams();

    useEffect(async () => {
        if(state.datasets.detail === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: DatasetActions.REQUEST_GET_DATASET_DETAIL, payload: {id: dataset_id} });
        } else {
            if(state.datasets.detail._id !== dataset_id) {
                dispatch({ type: DatasetActions.RESPONSE_GET_DATASET_DETAIL_FAILED });
            } else {
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }
        }
    }, [state.datasets.detail, dataset_id]);

    const _appName = () => {
        return (_.isEmpty(state.datasets.detail?.name)) ? dataset_id : state.datasets.detail.name;
    };

    const _renderAssetInformation = () => {
        if(_.isEmpty(state.datasets.detail)) {
            return (<div />);
        }

        if(_.isEmpty(state.datasets.detail.public_endpoint)) {
            return (
                <table>
                    <tbody>
                    <tr>
                        <td>Type:</td>
                        <td>Dataset</td>

                        <td>S3 Endpoint:</td>
                        <td>{state.datasets.detail.s3_endpoint}</td>
                    </tr>
                    <tr>
                        <td>Name:</td>
                        <td>{state.datasets.detail.name}</td>

                        <td>S3 File URI:</td>
                        <td>{state.datasets.detail.s3_file_uri}</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>{state.datasets.detail.status}</td>

                        <td>S3 Policy Name:</td>
                        <td>{state.datasets.detail.s3_policy_name}</td>
                    </tr>
                    <tr>
                        <td>Owner:</td>
                        <td>{state.datasets.detail.owner}</td>

                        <td>S3 Result URI:</td>
                        <td>{state.datasets.detail.s3_result_uri}</td>
                    </tr>
                    <tr>
                        <td>Description:</td>
                        <td colSpan={3}>{state.datasets.detail.description}</td>
                    </tr>
                    </tbody>
                </table>
            );
        }

        return (
            <table>
                <tbody>
                <tr>
                    <td>Type:</td>
                    <td>Dataset</td>
                </tr>
                <tr>
                    <td>Name:</td>
                    <td>{state.datasets.detail.name}</td>
                </tr>
                <tr>
                    <td>Status:</td>
                    <td>{state.datasets.detail.status}</td>
                </tr>
                <tr>
                    <td>Owner:</td>
                    <td>{state.datasets.detail.owner}</td>
                </tr>
                <tr>
                    <td>Public Endpoint:</td>
                    <td>{state.datasets.detail.public_endpoint}</td>
                </tr>
                <tr>
                    <td>Description:</td>
                    <td>{state.datasets.detail.description}</td>
                </tr>
                </tbody>
            </table>
        );
    }

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const _renderDeletionButton = () => {
        if(_.isEmpty(state.datasets.detail)) { return (<div />); }

        return (
            <div className="actions">
                <div className="remove-dataset">
                    <div className="description">
                        <strong>Remove this Dataset</strong>
                        <p>Once you remove a dataset, there is no going back. Please be certain!</p>
                    </div>
                    <div className="action">
                        <Button onClick={() => _requestRemoveCollaboration()} color="error" variant="outlined" startIcon={<DeleteForeverIcon />}>Remove this Dataset</Button>
                    </div>
                </div>
            </div>
        );
    }
    const _renderConfirmationModal = () => {
        if(!openConfirmationModal) { return (<div />); }

        const _submit = async (e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            dispatch({ type: DatasetActions.REQUEST_DELETE_DATASET, payload: {id: dataset_id} });
            setTimeout(function () {
                window.location.replace('/datasets');
            }, 1000);
        }

        return (
            <Modal
                id="dataset-detail-confirmation-modal"
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openConfirmationModal}>
                    <Box className="modal-content">
                        <form onSubmit={_submit}>
                            <div className="header">
                                <h2 className="title">Delete this Dataset?</h2>
                            </div>
                            <div className="content">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>Type:</td>
                                        <td>Dataset</td>
                                    </tr>
                                    <tr>
                                        <td>Name:</td>
                                        <td>{state.datasets.detail.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>{state.datasets.detail.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Owner:</td>
                                        <td>{state.datasets.detail.owner}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="footer">
                                <Button variant="outlined" onClick={() => setOpenConfirmationModal(false)}>Cancel</Button>
                                <Button variant="contained" type="submit" color="error">Delete It!</Button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        );
    }
    const _requestRemoveCollaboration = () => {
        setOpenConfirmationModal(true);
    }

    return (
        <AppLayout>
            <div id="dataset-detail">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/datasets" >
                                <CategoryIcon className="icon"/>
                                <span>Datasets</span>
                            </Link>
                        </li>
                        <li className="divider">/</li>
                        <li>{ _appName() }</li>
                    </ul>
                </div>

                <div className="card">
                    <h3 className="card-header">Asset's Information</h3>

                    { _renderAssetInformation() }

                    { _renderDeletionButton() }

                    { _renderConfirmationModal() }
                </div>
            </div>
        </AppLayout>
    );
}
