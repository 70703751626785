import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import _ from "lodash";

import PartnerDetailLayout from "./layout";
import {context} from "../../../context";
import PartnerActions from '../../../context/actions/partners';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from "@mui/material/Button";

export default () => {
    const {state, dispatch} = useContext(context);
    const {collaboration_id} = useParams();

    useEffect(async () => {
        if(state.partners.detail.shared === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNER_SHARED_ASSETS, payload: {collaborationId: collaboration_id} });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.partners.detail.shared, collaboration_id]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let partnerSharedItems = _.get(state, 'partners.detail.shared.items', []);
    let totalRecord = partnerSharedItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);
    const _renderPartnerSharedData = () => {

        const _submitAction = (type, asset_id) => {
            const requestType = (type === 'accept') ? PartnerActions.REQUEST_ACCEPT_ASSET_REQUEST : PartnerActions.REQUEST_REFUSE_ASSET_REQUEST;

            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: requestType, payload: {collaborationId: collaboration_id, assetId: asset_id} });
        };

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listPartnerSharedItems = partnerSharedItems.filter(function (item, index) {
            return index >= from && index <= to;
        }).map(function (item) {
            let requestType = (state.authenticated_user._id === item.user_id) ?
                <span className="type request_out"><ArrowUpwardIcon color="action" /> Outgoing</span>
                :
                <span className="type request_in"><ArrowDownwardIcon color="success" /> Incoming</span>;

            let actions = () => {
                if((state.authenticated_user._id === item.collaborator_id) && (item.status.toLowerCase() === 'waiting')) {
                    return (
                        <span className="actions">
                            <Button onClick={() => _submitAction('accept', item.asset_id)} color="success" variant="outlined" startIcon={<CheckCircleOutlineIcon />}>Accept</Button>
                            <Button onClick={() => _submitAction('refuse', item.asset_id)} color="error" variant="outlined" startIcon={<HighlightOffIcon />}>Refuse</Button>
                        </span>
                    );
                }

                return <span className={`status ${item.status.toLowerCase()}`}>{item.status.toLowerCase()}</span>;
            };

            return (
                <tr key={item._id} className={(state.authenticated_user._id === item.user_id) ? "request_out" : ""}>
                    <td>{item.asset_name}</td>
                    <td>{item.type}</td>
                    <td>{requestType}</td>
                    <td>{actions()}</td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Asset Name</th>
                    <th>Asset Type</th>
                    <th>Request Type</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { listPartnerSharedItems }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.partners.detail.shared.items.length < state.partners.detail.shared.total)) {
                dispatch({ type: PartnerActions.REQUEST_GET_PARTNER_SHARED_ASSETS, payload: {collaborationId: collaboration_id} });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    return (
        <PartnerDetailLayout>
            <div className="card" id="shared-assets-tabs">
                <h2 className="card-header">Shared Assets</h2>

                <Tabs value={0} className="tabs">
                    <Tab component={Link} label="All" to={`/partners/${collaboration_id}`} />
                    <Tab component={Link} label="Applications" to={`/partners/${collaboration_id}/app`} />
                    <Tab component={Link} label="Datasets" to={`/partners/${collaboration_id}/dataset`} />
                </Tabs>

                { _renderPartnerSharedData() }
                { _renderPagination() }
            </div>
        </PartnerDetailLayout>
    );
};
