import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import _ from "lodash";

import PartnerDetailLayout from "./layout";
import {context} from "../../../context";
import PartnerActions from '../../../context/actions/partners';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EarbudsIcon from '@mui/icons-material/Earbuds';
import Button from "@mui/material/Button";

export default () => {
    const {state, dispatch} = useContext(context);
    const {collaboration_id} = useParams();

    useEffect(async () => {
        if(state.partners.detail.datasets === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: PartnerActions.REQUEST_GET_PARTNER_DATASETS, payload: {collaborationId: collaboration_id} });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.partners.detail.datasets]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let partnerDatasetItems = _.get(state, 'partners.detail.datasets.items', []);
    let totalRecord = partnerDatasetItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);
    const _renderPartnerDatasets = () => {

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listPartnerDatasetItems = partnerDatasetItems.filter(function (dataset, index) {
            return index >= from && index <= to;
        }).map(function (dataset) {
            const _submitRequest = () => {
                dispatch({ type: "SHOW_GLOBAL_LOADING" });
                dispatch({ type: PartnerActions.REQUEST_PARTNER_SHARE_ASSET, payload: {collaborationId: collaboration_id, assetId: dataset._id} });
            };

            let actions = () => {
                if(_.isEmpty(dataset.request_status) || (dataset.request_status === 'deleted')) {
                    return (
                        <span className="actions">
                            <Button onClick={() => _submitRequest()} color="primary" variant="outlined" startIcon={<EarbudsIcon />}>Request</Button>
                        </span>
                    );
                } else {
                    return (
                        <span className={`status ${dataset.request_status.toLowerCase()}`}>{dataset.request_status.toLowerCase()}</span>
                    );
                }
            };

            return (
                <tr key={dataset._id}>
                    <td>{dataset.name}</td>
                    <td>{dataset.description}</td>
                    <td>{actions()}</td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { listPartnerDatasetItems }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.partners.detail.datasets.items.length < state.partners.detail.datasets.total)) {
                dispatch({ type: PartnerActions.REQUEST_GET_PARTNER_DATASETS, payload: {collaborationId: collaboration_id} });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    return (
        <PartnerDetailLayout>
            <div className="card" id="datasets-tab">
                <h2 className="card-header">Shared Assets</h2>

                <Tabs value={2} className="tabs">
                    <Tab component={Link} label="All" to={`/partners/${collaboration_id}`} />
                    <Tab component={Link} label="Applications" to={`/partners/${collaboration_id}/app`} />
                    <Tab component={Link} label="Datasets" to={`/partners/${collaboration_id}/dataset`} />
                </Tabs>

                { _renderPartnerDatasets() }
                { _renderPagination() }
            </div>
        </PartnerDetailLayout>
    );
};
