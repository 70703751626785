import ApplicationActions from '../actions/applications';
import DatasetActions from '../actions/datasets';
import EnclaveActions from '../actions/enclaves';
import DashboardActions from '../actions/dashboard';

export default (state, action) => {
    let localDashboardData;
    switch(action.type) {
        case DashboardActions.REQUEST_GET_DASHBOARD_DATA:
        case DashboardActions.RESPONSE_GET_DASHBOARD_DATA_SUCCESS:
            localDashboardData = window.localStorage.getItem('dashboard_data');
            localDashboardData = JSON.parse(localDashboardData);
            return localDashboardData;

        case ApplicationActions.RESPONSE_CREATE_NEW_APPLICATION_SUCCESS:
            localDashboardData = window.localStorage.getItem('dashboard_data');
            localDashboardData = JSON.parse(localDashboardData);
            localDashboardData = {
                ...localDashboardData,
                applications: localDashboardData.applications + 1
            };
            window.localStorage.setItem('dashboard_data', JSON.stringify(localDashboardData));
            return localDashboardData;

        case ApplicationActions.RESPONSE_DELETE_APPLICATION_SUCCESS:
            localDashboardData = window.localStorage.getItem('dashboard_data');
            localDashboardData = JSON.parse(localDashboardData);
            localDashboardData = {
                ...localDashboardData,
                applications: localDashboardData.applications - 1
            };
            window.localStorage.setItem('dashboard_data', JSON.stringify(localDashboardData));
            return localDashboardData

        case DatasetActions.RESPONSE_CREATE_NEW_DATASET_SUCCESS:
            localDashboardData = window.localStorage.getItem('dashboard_data');
            localDashboardData = JSON.parse(localDashboardData);
            localDashboardData = {
                ...localDashboardData,
                datasets: localDashboardData.datasets + 1
            };
            window.localStorage.setItem('dashboard_data', JSON.stringify(localDashboardData));
            return localDashboardData;

        case DatasetActions.RESPONSE_DELETE_DATASET_SUCCESS:
            localDashboardData = window.localStorage.getItem('dashboard_data');
            localDashboardData = JSON.parse(localDashboardData);
            localDashboardData = {
                ...localDashboardData,
                datasets: localDashboardData.datasets - 1
            };
            window.localStorage.setItem('dashboard_data', JSON.stringify(localDashboardData));
            return localDashboardData;

        case EnclaveActions.RESPONSE_CREATE_NEW_ENCLAVE_SUCCESS:
            localDashboardData = window.localStorage.getItem('dashboard_data');
            localDashboardData = JSON.parse(localDashboardData);
            localDashboardData = {
                ...localDashboardData,
                enclaves: localDashboardData.enclaves + 1,
                results: localDashboardData.results + 1
            };
            window.localStorage.setItem('dashboard_data', JSON.stringify(localDashboardData));
            return localDashboardData;

        default:
            return state;
    }
};
