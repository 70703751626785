import axios from "axios";
import _ from "lodash";

import Actions from '../actions/collaborations';

export default async (state, action, dispatch) => {
    switch(action.type) {
        case Actions.REQUEST_GET_COLLABORATIONS:
            await _getCollaborations(state, action, dispatch);
            break;

        case Actions.REQUEST_GET_COLLABORATION_DETAIL:
            await _getCollaborationDetail(state, action, dispatch);
            break;

        case Actions.REQUEST_CREATE_NEW_COLLABORATION:
            await _createCollaboration(state, action, dispatch);
            break;

        case Actions.REQUEST_ACCEPT_COLLABORATION:
            await _acceptCollaboration(state, action, dispatch);
            break;

        case Actions.REQUEST_REFUSE_COLLABORATION:
            await _refuseCollaboration(state, action, dispatch);
            break;
    }
}

const _getCollaborations = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/collaborations`,
            params: {
                page: _.get(state, 'collaborations.current_page', 0) + 1
            },
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        dispatch({ type: Actions.RESPONSE_GET_COLLABORATIONS_SUCCESS, payload: appResponse.data });
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }

};

const _getCollaborationDetail = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/collaborations/${action.payload}`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`}
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_GET_COLLABORATION_DETAIL_SUCCESS, payload: appResponse.data });
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }
};

const _createCollaboration = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/collaborations`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
            data: {email: action.payload.email}
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            dispatch({ type: Actions.RESPONSE_CREATE_NEW_COLLABORATION_SUCCESS, payload: appResponse.data });
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: {
                    open: true,
                    type: 'success',
                    message: 'Collaboration request was sent!',
                }
            });

            setTimeout(function () {
                window.location.href = '/collaborations';
            }, 1000);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }

};

const _acceptCollaboration = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/collaborations/${action.payload}`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            setTimeout(function () {
                dispatch({ type: Actions.RESPONSE_ACCEPT_COLLABORATION_SUCCESS, payload: appResponse.data });
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'Collaboration request was accepted!',
                    }
                });
            }, 500);
            setTimeout(function () {
                window.location.reload();
            }, 1500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }

};

const _refuseCollaboration = async (state, action, dispatch) => {
    try {
        const httpResponse = await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_API_ENDPOINT}/v1/collaborations/${action.payload}`,
            headers: {'Authorization': `Bearer ${state.authenticated_user.access_token}`},
        });
        let appResponse = httpResponse.data;
        if(appResponse.code === 200) {
            setTimeout(function () {
                dispatch({ type: Actions.RESPONSE_REFUSE_COLLABORATION_SUCCESS, payload: appResponse.data });
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: {
                        open: true,
                        type: 'success',
                        message: 'Collaboration request was refused!',
                    }
                });
            }, 500);
            setTimeout(function () {
                window.location.reload();
            }, 1500);
        } else {
            setTimeout(function () {
                dispatch({
                    type: "SHOW_GLOBAL_MESSAGE",
                    payload: { open: true, type: 'error', message: appResponse.message }
                });
                dispatch({ type: "HIDE_GLOBAL_LOADING" });
            }, 500);
        }
    } catch (e) {
        setTimeout(function () {
            dispatch({
                type: "SHOW_GLOBAL_MESSAGE",
                payload: { open: true, type: 'error', message: e.message }
            });
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }, 500);
        console.log(e.message);
    }

};
