import './index.scss';
import React, {useEffect, useState, useContext} from 'react';
import _ from "lodash";

import AppLayout from "../../components/layouts/app";
import {context} from "../../context";
import NotificationActions from '../../context/actions/notifications';
import {Link} from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

export default () => {
    const {state, dispatch} = useContext(context);

    useEffect(async () => {
        if(state.notifications === null) {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });
            dispatch({ type: NotificationActions.REQUEST_GET_NOTIFICATIONS });
        } else {
            dispatch({ type: "HIDE_GLOBAL_LOADING" });
        }
    }, [state.notifications]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    let notificationItems = _.get(state, 'notifications.items', []);
    let totalRecord = notificationItems.length;
    let pageSize = 10;
    let totalPage = Math.floor((totalRecord + pageSize - 1) / pageSize);
    const _renderNotifications = () => {

        let from = (paginationCurrentPage - 1) * pageSize;
        let to = paginationCurrentPage * pageSize - 1;
        const listNotifications = notificationItems.filter(function (notification, index) {
            return index >= from && index <= to;
        }).map(function (notification) {
            let link = notification.type === 'collaborations' ? "/collaborations" : "/";
            let data = notification.data;

            if(notification.type === 'collaborations') {
                link = "/collaborations";
            } else if(notification.type === 'assets') {
                link = `/partners/${data.collaboration_id}`;
            } else if(notification.type === 'results') {
                link = "/results";
            }

            return (
                <tr key={notification._id}>
                    <td>
                        <a href={link}>{notification.content}</a>
                    </td>
                    <td>{notification.created_at}</td>
                </tr>
            );
        });

        return (
            <table>
                <thead>
                <tr>
                    <th>Content</th>
                    <th>Created At</th>
                </tr>
                </thead>
                <tbody>
                { listNotifications }
                </tbody>
            </table>
        );
    }
    const _renderPagination = () => {
        if(totalPage < 2) { return <div className="pagination" /> }

        const _changePage = async (pageNumber) => {
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            if(((pageNumber + 1) >= totalPage) && (state.notifications.items.length < state.notifications.total)) {
                dispatch({ type: NotificationActions.REQUEST_GET_NOTIFICATIONS });
            }

            setTimeout(function () {setPaginationCurrentPage(pageNumber);}, 200);
            setTimeout(function () {dispatch({ type: "HIDE_GLOBAL_LOADING" });}, 500);
        }

        let to = Math.min(totalPage, Math.max(paginationCurrentPage + 2, 5));
        let from = Math.max(1, to - 4);
        let li = [];
        for( let i = from; i <= to; i++) {
            li.push(
                <li key={i} className={i === paginationCurrentPage ? "current" : ""} onClick={() => _changePage(i)}>{i}</li>
            );
        }

        return (
            <div className="pagination">
                <ul>
                    <li className="first" onClick={() => _changePage(1)}>&laquo;</li>
                    {li}
                    <li className="last" onClick={() => _changePage(totalPage)}>&raquo;</li>
                </ul>
            </div>
        );
    }

    return (
        <AppLayout>
            <div id="notifications-index">
                <div className="page-header">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/notifications" className="disabled" >
                                <NotificationsNoneIcon className="icon"/>
                                <span>Notifications</span>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="card">
                    { _renderNotifications() }

                    { _renderPagination() }
                </div>
            </div>
        </AppLayout>
    );
};
