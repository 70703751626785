export default {
    REQUEST_GET_ATTESTATIONS: "REQUEST_GET_ATTESTATIONS",
    RESPONSE_GET_ATTESTATIONS_SUCCESS: "RESPONSE_GET_ATTESTATIONS_SUCCESS",
    RESPONSE_GET_ATTESTATIONS_FAILED: "RESPONSE_GET_ATTESTATIONS_FAILED",

    REQUEST_GET_ATTESTATION_DETAIL: "REQUEST_GET_ATTESTATION_DETAIL",
    RESPONSE_GET_ATTESTATION_DETAIL_SUCCESS: "RESPONSE_GET_ATTESTATION_DETAIL_SUCCESS",
    RESPONSE_GET_ATTESTATION_DETAIL_FAILED: "RESPONSE_GET_ATTESTATION_DETAIL_FAILED",

}
