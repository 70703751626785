import './signin.scss';
import React, {useEffect, useState, useContext} from 'react';

import {GitHub as GitHubIcon, Key as KeyIcon} from "@mui/icons-material";
import {TextField, Button} from '@mui/material';

import GuestLayout from "../../components/layouts/guest";
import {context} from "../../context";
import AuthActions from '../../context/actions/auth';

export default () => {

    const {state, dispatch} = useContext(context);
    const [account, setAccount] = useState({email: '', password: ''});

    const _submit = async (e) => {
        e.preventDefault();
        dispatch({ type: "SHOW_GLOBAL_LOADING" });
        dispatch({ type: AuthActions.REQUEST_SIGNIN, payload: {...account} });
    }

    return (
        <GuestLayout>
            <div id="signin">
                <div className="wrapper">
                    <div className="left">
                        <h1 className="logo">
                            Welcome to <br/> Confidential Cloud Studio
                        </h1>
                        <span className="description">Easy, secure and compliant data collaboration </span>
                    </div>
                    <div className="right">
                        <form onSubmit={_submit} className="form" id="signin-form">
                            <TextField
                                id="input-email"
                                className="input email"
                                type="email"
                                label="Enter your email"
                                variant="outlined"
                                autoComplete="off"
                                onChange={(e) => setAccount({...account, email: e.target.value})}
                                value={account.email}
                                required
                            />

                            <TextField
                                id="input-password"
                                className="input password"
                                type="password"
                                label="Enter your password"
                                variant="outlined"
                                onChange={(e) => setAccount({...account, password: e.target.value})}
                                value={account.password}
                                required
                            />

                            <Button type="submit" className="submit-btn" id="submit-btn">Sign In</Button>

                            <ul className="helpers">
                                <li>
                                    <a href="/signup">Don't have an account?</a>
                                </li>
                            </ul>

                            <p className="divider">
                                <span>Or log in with</span>
                            </p>

                            <ul className="social-signin">
                                <li>
                                    <GitHubIcon className="icon"/> GitHub
                                </li>
                                <li>
                                    <KeyIcon className="icon"/> SSO
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </GuestLayout>
    );
}
