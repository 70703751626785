import _ from "lodash";

import Actions from '../actions/applications';

export default (state, action) => {
    switch(action.type) {
        case Actions.RESPONSE_GET_APPLICATIONS_SUCCESS:
            return {
                ...state,
                index: {
                    items: state.index?.items ? [...state.index.items, ...action.payload.items] : action.payload.items,
                    total: action.payload.total,
                    page_size: action.payload.page_size,
                    current_page: action.payload.current_page,
                }
            }

        case Actions.RESPONSE_GET_APPLICATION_DETAIL_SUCCESS:
            return {
                ...state,
                detail: action.payload
            };

        case Actions.RESPONSE_GET_APPLICATION_DETAIL_FAILED:
            return {
                ...state,
                detail: null
            };

        case Actions.RESPONSE_CREATE_NEW_APPLICATION_SUCCESS:
            return {
                ...state,
                index: {
                    ...state.index,
                    items: [
                        action.payload,
                        ...state.index.items
                    ],
                    total: state.index.total + 1
                }
            }

        case Actions.RESPONSE_DELETE_APPLICATION_SUCCESS:
            let newApplicationCollection = _.filter(state.index.items, function(app) { return (app._id !== action.payload.id); });
            return {
                ...state,
                index: {
                    ...state.index,
                    items: newApplicationCollection,
                    total: state.index.total - 1
                }
            }

        default:
            return state;
    }
};
