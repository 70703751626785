import './common.scss';
import './app.scss';
import React, {useState, useEffect, useContext} from 'react';
import { gql, useQuery, useMutation , useSubscription } from '@apollo/client';

import {context} from "../../context";
import ProfileActions from "../../context/actions/profile";
import CircularProgress from "@mui/material/CircularProgress";

import {Link} from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BarChartIcon from "@mui/icons-material/BarChart";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import CategoryIcon from "@mui/icons-material/Category";
import CodeIcon from "@mui/icons-material/Code";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import PaymentsIcon from '@mui/icons-material/Payments';
import {Snackbar, Alert, AlertTitle, Slide } from '@mui/material';
import _ from "lodash";
import moment from 'moment';


const NOTIFICATION_SUBSCRIPTION = gql`
  subscription {
    hasNewNotification
  }
`;

const GET_NOTIFICATIONS = gql`
    query {
      getNotifications {
        _id
        title
        content
        type
        data
        created_at
      }
    }
`;

const UPDATE_LAST_NOTIFICATION_ID = gql`
  mutation ($last_notification_id: String!) {
    updateLastNotificationId(last_notification_id: $last_notification_id)
  }
`;


export default ({children}) => {
    const {state, dispatch} = useContext(context);
    const subscriptionResponse = useSubscription( NOTIFICATION_SUBSCRIPTION );
    const queryResponse = useQuery(GET_NOTIFICATIONS);
    const [updateLastNotificationId, { data, loading, error }] = useMutation(UPDATE_LAST_NOTIFICATION_ID);

    useEffect(
        async () => {
            if(!_.isEmpty(subscriptionResponse.data) && (subscriptionResponse.data.hasNewNotification === state.authenticated_user._id)) {
                setTotalUnreadNotification(totalUnreadNotification + 1);
                subscriptionResponse.data = null;

                let refetch = await queryResponse.refetch();
                setNotifications(refetch.data.getNotifications);
            } else if(!_.isEmpty(queryResponse.data)) {
                setNotifications(queryResponse.data.getNotifications);

                let totalUnread = 0;
                for(let i = 0; i < queryResponse.data.getNotifications.length; i++) {
                    let notification = queryResponse.data.getNotifications[i];

                    if(notification._id !== state.authenticated_user.last_notification_id) {
                        totalUnread += 1;
                    } else {
                        break;
                    }
                }
                setTotalUnreadNotification(totalUnread);
            }
        },
        [queryResponse, subscriptionResponse]
    );

    const [totalUnreadNotification, setTotalUnreadNotification] = useState(0);
    const [isShowListNotifications, setIsShowListNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const _renderAppHeader = () => {

        const _showListNotifications = async () => {
            if(!isShowListNotifications) {
                if(notifications.length) {
                    await updateLastNotificationId({ variables: { last_notification_id: notifications[0]._id } });
                }
            } else {
                dispatch({type: ProfileActions.REQUEST_GET_AUTHENTICATED_USER}); // to get new last_notification_id
                setTotalUnreadNotification(0);
            }
            setIsShowListNotifications(!isShowListNotifications);
        };

        const _renderNotifications = () => {
            if(!notifications) {
                return <div className="detail" />
            }

            let li = [];
            _.forEach(notifications, function (notification, index) {
                if(index >= 7) return false;

                let link = notification.type === 'collaborations' ? "/collaborations" : "/";
                let data = JSON.parse(notification.data);

                if(notification.type === 'collaborations') {
                    link = "/collaborations";
                } else if(notification.type === 'assets') {
                    link = `/partners/${data.collaboration_id}`;
                } else if(notification.type === 'results') {
                    link = "/results";
                }

                li.push(
                    <li key={notification._id} className={(index < totalUnreadNotification) ? "unread" : ""}>
                        <a href={link}>
                            {
                                data.image_url ?
                                    <img src={data.image_url} alt=""/>
                                    :
                                    <PersonIcon />
                            }
                            <span className="content">
                                {notification.content}
                                <span className="time">{moment(parseInt(notification.created_at)).fromNow()}</span>
                            </span>
                        </a>
                    </li>
                );
            });

            return (
                <div className="detail">
                    <ul>
                        {li}
                        <li className="show-all">
                            <a href="/notifications">View All</a>
                        </li>
                    </ul>
                </div>
            );
        };

        return (
            <div id="header">
                <div className="brand">
                    <h2 className="name">
                        <Link to="/">
                            <img src="/confidential_cloud_studio.png" width="250"></img>
                        </Link>
                    </h2>
                </div>

                <div className="navbar">
                    <div className="wrapper">
                        <div className="search">
                            <input type="text" placeholder="Search..." />
                            <SearchOutlinedIcon />
                        </div>
                        <div className="items">
                            {/*<div className="item">*/}
                                {/*<DarkModeOutlinedIcon className="icon" onClick={() => dispatch({ type: "TOGGLE" })}*/}
                                {/*/>*/}
                            {/*</div>*/}
                            <div className={isShowListNotifications ? "item notifications activated" : "item notifications"} onClick={() => _showListNotifications()}>
                                <NotificationsNoneOutlinedIcon className="icon" />
                                <div className={totalUnreadNotification ? "counter" : ""}>{totalUnreadNotification ? totalUnreadNotification : ""}</div>
                                { _renderNotifications() }
                            </div>
                            {/*<div className="item">*/}
                                {/*<ChatBubbleOutlineOutlinedIcon className="icon" />*/}
                                {/*<div className="counter">2</div>*/}
                            {/*</div>*/}
                            {/*<div className="item">*/}
                                {/*<ListOutlinedIcon className="icon" />*/}
                            {/*</div>*/}
                            <div className="item user">
                                <div className="avatar">
                                    {
                                        state.authenticated_user.company?.logo_url ?
                                            <img src={state.authenticated_user.company.logo_url} alt="" className="" />
                                            :
                                            <PersonIcon />
                                    }
                                </div>
                                <p className="name">
                                    {`${state.authenticated_user.first_name} ${state.authenticated_user.last_name}`}
                                    <span>{state.authenticated_user.company?.name || "Unknown Company"}</span>
                                </p>
                                <a href="/profile" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const _renderAppSidebar = () => {

        const _logout = (e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_GLOBAL_LOADING" });

            setTimeout(function () {
                window.localStorage.removeItem('access_token');
                window.location.replace('/signin');
            }, 1000);
        }

        let componentName = window.location.pathname.split('/')[1];

        return (
            <div id="sidebar">
                <ul>
                    <p className="title">MAIN</p>
                    <Link to="/" className={(componentName === "") ? "link activated" : "link"}>
                        <li>
                            <DashboardIcon className="icon"/>
                            <span>Dashboard</span>
                        </li>
                    </Link>

                    <p className="title">EXECUTION</p>
                    <Link to="/enclaves" className={(window.location.pathname === "/enclaves") ? "link activated" : "link"}>
                        <li>
                            <FeaturedVideoIcon className="icon"/>
                            <span>Enclaves</span>
                        </li>
                    </Link>
                    <Link to="/attestations" className={(componentName === "attestations") ? "link activated" : "link"}>
                        <li>
                            <VerifiedUserIcon className="icon"/>
                            <span>Attestations</span>
                        </li>
                    </Link>
                    <Link to="/results" className={(componentName === "results") ? "link activated" : "link"}>
                        <li>
                            <BarChartIcon className="icon"/>
                            <span>Results</span>
                        </li>
                    </Link>

                    <p className="title">COLLABORATION</p>
                    <Link to="/partners" className={(_.indexOf(["partners", "collaborations"], componentName) !== -1) ? "link activated" : "link"}>
                        <li>
                            <BusinessIcon className="icon"/>
                            <span>Partners</span>
                        </li>
                    </Link>
                    {/*<Link to="/collaborations" className={(window.location.pathname === "/collaborations") ? "link activated" : "link"}>*/}
                        {/*<li>*/}
                            {/*<HandshakeIcon className="icon"/>*/}
                            {/*<span>Collaborations</span>*/}
                        {/*</li>*/}
                    {/*</Link>*/}
                    <Link to="/datasets" className={(componentName === "datasets") ? "link activated" : "link"}>
                        <li>
                            <CategoryIcon className="icon"/>
                            <span>Datasets</span>
                        </li>
                    </Link>
                    <Link to="/applications" className={(componentName === "applications") ? "link activated" : "link"}>
                        <li>
                            <CodeIcon className="icon"/>
                            <span>Applications</span>
                        </li>
                    </Link>
                    <Link to="/logs" className={(componentName === "logs") ? "link activated" : "link"}>
                        <li>
                            <PsychologyOutlinedIcon className="icon"/>
                            <span>Audit Logs</span>
                        </li>
                    </Link>

                    <p className="title">USEFUL</p>

                    <Link to="/statistics" className="link disabled">
                        <li>
                            <InsertChartIcon className="icon"/>
                            <span>Stats</span>
                        </li>
                    </Link>
                    <Link to="/notifications" className={(componentName === "notifications") ? "link activated" : "link"}>
                        <li>
                            <NotificationsNoneIcon className="icon"/>
                            <span>Notifications</span>
                        </li>
                    </Link>
                    <Link to="/billing" className={(componentName === "billing") ? "link activated" : "link"}>
                        <li>
                            <PaymentsIcon className="icon"/>
                            <span>Billing</span>
                        </li>
                    </Link>

                    <p className="title">SERVICE</p>
                    <a href="https://confidentialcloud.statuspage.io/" target="_blank" className="link">
                        <li>
                            <SettingsSystemDaydreamOutlinedIcon className="icon"/>
                            <span>System Health</span>
                        </li>
                    </a>
                    <Link to="/settings" className="link disabled">
                        <li>
                            <SettingsApplicationsIcon className="icon"/>
                            <span>Settings</span>
                        </li>
                    </Link>

                    <p className="title">USER</p>
                    <Link to="/profile" className={(componentName === "profile") ? "link activated" : "link"}>
                        <li>
                            <AccountCircleOutlinedIcon className="icon"/>
                            <span>Profile</span>
                        </li>
                    </Link>
                    <Link to="/logout" className="link">
                        <li onClick={_logout}>
                            <ExitToAppIcon className="icon"/>
                            <span>Logout</span>
                        </li>
                    </Link>
                </ul>
            </div>
        );
    }

    const _hideGlobalMessage = (e, reason) => {
        if (reason === 'clickaway') {
            return false;
        }

        dispatch({ type: "HIDE_GLOBAL_MESSAGE" });
        return true;
    }

    return (
        <div id="application">
            { _renderAppHeader() }

            <div id="content">
                { _renderAppSidebar() }

                <div id="main">
                    { children }
                </div>
            </div>

            <div id="loading" className={state.global_loading ? "show" : ""}>
                <CircularProgress/>
            </div>

            <Snackbar
                id="global-message"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.global_message.open}
                autoHideDuration={5000}
                onClose={_hideGlobalMessage}
                TransitionComponent={Slide}
            >
                <Alert onClose={_hideGlobalMessage} severity={state.global_message.type}>
                    <AlertTitle style={{textTransform: "capitalize"}}>{state.global_message.type}</AlertTitle>
                    {state.global_message.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
